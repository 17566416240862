import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* Entry Point */}
    <GoogleOAuthProvider clientId="276758759596-rn51u9vlarh2m3a1fnu1se3t0iev9e9d.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </Provider>
);
