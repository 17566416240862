import React, { useEffect, useState } from "react";
import "./UserPending.css";

const BlockedUser = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    const text =
      "Your Affiliate profile is rejected/Blocked. Please Contact admin";
    let index = 0;

    const timer1 = setInterval(() => {
      if (index === text?.length) {
        clearInterval(timer1);
      } else {
        setContent(text.substring(0, index + 1));
        index++;
      }
    }, 50);

    return () => {
      clearInterval(timer1);
    };
  }, []);

  return (
    <div className="user-profile">
      <h1>Dashboard</h1>
      <p>{content}</p>
    </div>
  );
};

export default BlockedUser;
