import React from "react";
import "./faq.css";

const FAQ = () => {
  return (
    <section className="faq-section ">
      <div class="wrapper bg-white rounded shadow">
        <div class="h2 text-center fw-bold">Questions You May Have/FAQs</div>
        <br />
        <div
          class="accordion accordion-flush border-top border-start border-end"
          id="myAccordion"
        >
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                How can I track my commissions?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse border-0"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#myAccordion"
            >
              <div className="pad">
                <span style={{ fontSize: "15px" }}>
                  Tracking your commissions is easy. Once you're enrolled in our
                  affiliate program, you'll gain access to a personalized
                  dashboard. This dashboard provides real-time insights into
                  your referrals, commissions earned, and more, making it simple
                  to monitor your earnings.
                </span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                When do I get paid for my referrals?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse border-0"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#myAccordion"
            >
              <div className="pad">
                <span style={{ fontSize: "15px" }}>
                  We pay our affiliates as per the NET60 payment structure.
                  Refer to the{" "}
                  <a href="/terms-and-conditions">terms and conditions</a> page
                  for more information.
                </span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Can I earn commissions regularly?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse border-0"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#myAccordion"
            >
              <div className="pad">
                <span style={{ fontSize: "15px" }}>
                  Absolutely! There are no limits to the number of companies and
                  clients you can refer to seeking team augmentation services.
                  This opens up an opportunity for you to earn commissions
                  regularly as you continue to refer clients to us. We value
                  your referrals and appreciate your ongoing support in helping
                  us connect with businesses in need of our services.
                </span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                Are the commissions for the same client recurring?
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              class="accordion-collapse collapse border-0"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#myAccordion"
            >
              <div className="pad">
                <span style={{ fontSize: "15px" }}>
                  Yes, our one-time commission structure is an aspect of our
                  affiliate program for each client. But you have no limits to
                  the number of clients you can refer. Get a one-time commission
                  for every new client you refer who gets converted after
                  qualification. We believe in rewarding our affiliates
                  generously for their successful referrals, providing a
                  lucrative opportunity to boost your income.
                </span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                What other affiliate products does DataToBiz offer?
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              class="accordion-collapse collapse border-0"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#myAccordion"
            >
              <div className="pad">
                <span style={{ fontSize: "15px" }}>
                  Our affiliate program also offers PrepAI, an AI-based
                  question-generation platform. PrepAI provides the benefits of
                  generating High Order Thinking Skill (HOTS) questions,
                  seamless test conduct with automation, and easy API
                  integration. It's a powerful tool for educators, trainers, and
                  organizations looking to enhance their assessments and
                  evaluations. In addition to our Staff Augmentation services,
                  you can earn attractive commissions by referring “PrepAI” as
                  well.
                </span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSix"
                aria-expanded="false"
                aria-controls="flush-collapseSix"
              >
                Who is an affiliate?
              </button>
            </h2>
            <div
              id="flush-collapseSix"
              class="accordion-collapse collapse border-0"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#myAccordion"
            >
              <div className="pad">
                <span style={{ fontSize: "15px" }}>
                  An affiliate is an individual or entity that partners with a
                  business to promote and market its products or services in
                  exchange for a commission on sales. Essentially, affiliates
                  act as external collaborators who leverage their online
                  presence to drive traffic or customers to the business they
                  are partnered with. It's a win-win collaboration where
                  affiliates earn a commission for each successful referral they
                  generate.
                </span>{" "}
                <br />
                <br />
                <span style={{ fontSize: "15px" }}>
                  Affiliates can include bloggers, influencers, website owners,
                  marketers or anyone with a web presence interested in earning
                  income through promoting relevant products or services. The
                  relationship between an affiliate and a business is typically
                  established through an affiliate marketing program, where the
                  affiliate is provided with unique tracking links or forms to
                  generate leads and initiate earnings.
                </span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSeven"
                aria-expanded="false"
                aria-controls="flush-collapseSeveb"
              >
                How to get started with Affiliate Marketing?
              </button>
            </h2>
            <div
              id="flush-collapseSeven"
              class="accordion-collapse collapse border-0"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#myAccordion"
            >
              <div className="pad">
                <span style={{ fontSize: "15px" }}>
                  Starting with affiliate marketing is a process that requires
                  you to follow these quick steps:
                  <br />
                  <br />
                  <span className="d-flex" style={{ fontSize: "14px" }}>
                    <div className="disc">-</div>Choose a niche that aligns with
                    your interests or expertise, ensuring there's a demand for
                    the products or services within your target group(TG).
                  </span>{" "}
                  <br />
                  <span className="d-flex" style={{ fontSize: "14px" }}>
                    <div className="disc">-</div>Look for reputable affiliate
                    programs related to your chosen niche.
                  </span>{" "}
                  <br />
                  <span className="d-flex" style={{ fontSize: "14px" }}>
                    <div className="disc">-</div>Join the affiliate programs
                    you've chosen. This usually involves creating an account and
                    agreeing to the terms and conditions.
                  </span>{" "}
                  <br />
                  <span className="d-flex" style={{ fontSize: "14px" }}>
                    <div className="disc">-</div>Once approved, choose specific
                    products or services to promote. These products and services
                    should resonate with your target audience.
                  </span>{" "}
                  <br />
                  <span className="d-flex" style={{ fontSize: "14px" }}>
                    <div className="disc">-</div>Develop engaging content to
                    promote the products/services. This could include blog
                    posts, videos, social media posts, or email marketing.
                  </span>{" "}
                  <br />
                  <span className="d-flex" style={{ fontSize: "14px" }}>
                    <div className="disc">-</div>Most affiliate programs provide
                    unique tracking links or forms. Link them in your content to
                    ensure you receive credit for the sales or leads you
                    generate.
                  </span>{" "}
                  <br />
                  <span className="d-flex" style={{ fontSize: "14px" }}>
                    <div className="disc">-</div>Promote your content across
                    multiple channels to drive traffic to your affiliate links
                    via social media marketing, paid advertising, or email
                    campaigns.
                  </span>{" "}
                  <br />
                  <span className="d-flex" style={{ fontSize: "14px" }}>
                    <div className="disc">-</div>Identify what works well and
                    optimize your strategy accordingly. Tweak your content,
                    adjust your promotion methods, or focus on high-performing
                    products.
                  </span>{" "}
                  <br />
                </span>
    
                <span  style={{ fontSize: "14px" }}>
                  Remember, success in affiliate marketing often requires time
                  and patience. Establish trust with your audience, optimize
                  your online presence, engage across channels, and enjoy
                  attractive commissions.
                </span>
                <br />
                <br />
                <span  style={{ fontSize: "14px" }}>
                  Note: The introductory video above will give you a quick
                  glimpse of how to start with the DataToBiz Staff Augmentation
                  Affiliate Program.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
