import React from "react";
import styles from "./FooterHead.module.css";
import { Link } from "react-router-dom";

const FooterHead = () => {
  return (
    <div className={styles.footerHeadContainer}>
      <h1>Sign up now to start earning</h1>
      <Link to="/register" className={styles.button3}>
        <button className={styles.affilatebutton}>Become an Affiliate</button>
      </Link>
    </div>
  );
};

export default FooterHead;
