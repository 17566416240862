import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Notfound = ({ text="No data found!" }) => {
  return (
    <div
      style={{
        background: "#efdcdc",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap:'1rem',
        padding:'1rem 0'
      }}
    >
      <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "red",fontSize:'2.2rem' }} />
      <p style={{ color: "red",fontSize:'1.4rem',fontWeight:'500' }}>{text}</p>
    </div>
  );
};

export default Notfound;
