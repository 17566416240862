import React from "react";
import styles from "./Footer.module.css"; // Assuming you have a Footer.module.css file for styling
import FooterHead from "./FooterHead/FooterHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faPinterestP,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer = ({ hideCTA = false }) => {
  return (
    <>
      {!hideCTA && <FooterHead></FooterHead>}

      <footer id="main-footer" className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.footerWidgets}>
            <div className={styles.footerWidget}>
              <div className={styles.mediaImage}>
                <a
                  href="https://affiliate.datatobiz.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${process.env.REACT_APP_PROJECT_URL}/img/datatobiz-logo.svg`}
                    className={styles.imageFooter}
                    alt="blacklogo"
                    decoding="async"
                    loading="lazy"
                  />
                </a>
              </div>
              <div className={styles.customHTML}>
                <div className={styles.textWidget}>
                  <a
                    className={styles.footerBtnLink}
                    href="http://datatobiz.com/"
                    target="_blank"
                  >
                    <span className={styles.footerBtn}>Visit Main Website</span>
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.footerWidget}>
              <div className={styles.navMenu}>
                <h4 className={styles.title}>Solutions</h4>
                <div className={styles.menuSolutionsContainer}>
                  <ul className={styles.menu}>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/artificial-intelligence/"
                        target="_blank"
                      >
                        Artificial Intelligence
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/data-analytics/"
                        target="_blank"
                      >
                        Data Analytics
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/data-warehousing/"
                        target="_blank"
                      >
                        Data Warehousing
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/natural-language-processing/"
                        target="_blank"
                      >
                        Natural Language Processing
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/vision-analytics/"
                        target="_blank"
                      >
                        Vision Analytics
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/big-data/"
                        target="_blank"
                      >
                        Big Data
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/business-intelligence/"
                        target="_blank"
                      >
                        Business Intelligence
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/power-bi-services-usa/"
                        target="_blank"
                      >
                        Power BI
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/hire-ai-engineers/"
                        target="_blank"
                      >
                        AI Staff Augmentation
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/hire-tableau-engineers/"
                        target="_blank"
                      >
                        Hire Tableau Engineers
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/hire-azure-data-engineers/"
                        target="_blank"
                      >
                        Hire Azure Data Engineers
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/automated-digital-lending-solutions/"
                        target="_blank"
                      >
                        Automated Digital Lending Solution
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.footerWidget}>
              <div className={styles.navMenu}>
                <h4 className={styles.title}>Explore</h4>
                <div className={styles.menuExploreContainer}>
                  <ul className={styles.menu}>
                    <li className={styles.menuItem}>
                      <a href="https://www.datatobiz.com/blog/" target="_blank">
                        Knowledge Base
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/case-studies/"
                        target="_blank"
                      >
                        Case Studies
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/industries/"
                        target="_blank"
                      >
                        Industries
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a href="https://careers.datatobiz.com/" target="_blank">
                        Careers
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/about/"
                        target="_blank"
                      >
                        About
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/contact/"
                        target="_blank"
                      >
                        Contact
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a href="/terms-and-conditions/" target="_blank">
                        Terms and Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.footerWidget}>
              <div className={styles.navMenu}>
                <h4 className={styles.title}>Products</h4>
                <div className={styles.menuProductContainer}>
                  <ul className={styles.menu}>
                    <li className={styles.menuItem}>
                      <a href="http://prepai.in" target="_blank">
                        PrepAI
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a href="https://hirelake.ai/" target="_blank">
                        HireLakeAI
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a href="http://datatobiz.com" target="_blank">
                        DataToBiz
                      </a>
                    </li>
                    <li className={styles.menuItem}>
                      <a
                        href="https://www.datatobiz.com/virtual-try-on/"
                        target="_blank"
                      >
                        Virtual Try On
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.customHTML}>
                <h4 className={styles.title}>Follow Us </h4>
                <div className={styles.textWidget}>
                  <div className={styles.socialMenuFooter}>
                    <ul className={styles.socialUl}>
                      <li>
                        <a href="https://twitter.com/DataToBiz" target="_blank">
                          <FontAwesomeIcon
                            icon={faTwitter}
                            className={styles.icon}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/datatobiz/"
                          target="_blank"
                          rel="noopener"
                        >
                          <FontAwesomeIcon
                            icon={faInstagram}
                            className={styles.icon}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="//www.linkedin.com/company/datatobiz/"
                          target="_blank"
                          rel="noopener"
                        >
                          <FontAwesomeIcon
                            icon={faLinkedin}
                            className={styles.icon}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/datatobiz"
                          target="_blank"
                        >
                          <FontAwesomeIcon
                            icon={faFacebook}
                            className={styles.icon}
                          />
                        </a>
                      </li>
                      <li>
                        <a href="https://pin.it/1CjPZUo" target="_blank">
                          <FontAwesomeIcon
                            icon={faPinterest}
                            className={styles.icon}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className={styles.footerBottom}>
          <div className={styles.container}>
            <div id="footer-info">
              © 2023 DataToBiz
              <span className={styles.trademark}>
                <sup>TM</sup>
              </span>{" "}
              All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
