import React, { useState, useEffect, useRef } from "react";
import styles from "./SignupForm.module.css";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { setAuthToken, api } from "../../../Api";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { notifyError, notifySuccess } from "../Toaster/Toaster";
import { validateEmail, validateFullName } from "../Helper";
import { useDispatch } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { isLoader, setUser } from "../../../Redux/actions/userActions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { countryList } from "../../../helpers/countryList";
import Swal from "sweetalert2";
const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const LabelCustom = ({ isReq, text }) => {
  return (
    <label
      id="technology-label"
      htmlFor="technology-input"
      className="hs-form__field__label"
      data-required="true"
      style={{ fontSize: "14px" }}
    >
      <span style={{ marginLeft: "7px", color: !isReq && "gray" }}>{text}</span>
      {isReq && <span className="hs-form__field__label__required">*</span>}
    </label>
  );
};

const Signup = () => {
  // Inside your component
  const location = useLocation();

  const [formValues, setFormValues] = useState({
    userName: "",
    email: "",
    fullName: "",
    password: "",
    country_code: "IN",
    dial_code: "+91",
    formatted_phone_number: "+91 ",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [others, setOthers] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState(null);
  const [verified, setVerified] = useState(true);
  const [loginSuccess, setLoginSuccess] = useState(null);

  const [verificationToken, setVerificationToken] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let phoneRef = useRef();

  const isValidURL = (url) => {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return pattern.test(url);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // VALIDATION BLOCK
    if (!formValues.fullName) {
      notifyError("Full Name is Required");
      return;
    }

    if (!formValues?.email) {
      notifyError("Email is Required");
      return;
    }
    if (!validateEmail(formValues.email)) {
      notifyError("Enter a valid Email Address");
      return;
    }
    if (!formValues?.password) {
      notifyError("Password is Required");
      return;
    } else if (formValues.formatted_phone_number.length < 6) {
      notifyError("Phone Number is Required");
      return;
    }

    if (!formValues?.source) {
      notifyError("Where did you find us is Required");
      return;
    }
    let src = formValues.source;
    let aux_src = formValues.source;
    if (src == "Others") {
      if (!formValues.othersource) {
        notifyError("Please input others");
        return;
      }
      src = formValues.othersource;
    }
    if (!formValues?.country) {
      notifyError("Country is Required");
      return;
    }
    if (formValues.linkedin && !isValidURL(formValues.linkedin)) {
      notifyError("Enter a valid LinkedIn URL");
      return;
    }

    delete formValues.source;
    setIsRegistering(true);
    api
      .post("/auth/register", { ...formValues, source: src })
      .then((response) => {
        if (response.status === 200) {
          setVerified(false);
          setLoginSuccess("Welcome to DataToBiz!");
        } else {
          console.log("Error!");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setError("User already exists");
        } else {
          console.error(error);
        }
        setFormValues({
          ...formValues,
          source: aux_src,
        });
      })
      .finally(() => {
        setIsRegistering(false);
      });
  };

  const handlePasswordChange = (event) => {
    event.target.setCustomValidity("");
    setFormValues({ ...formValues, password: event.target.value });
  };

  const handleConfirmPasswordChange = (event) => {
    event.target.setCustomValidity("");
    setConfirmPassword(event.target.value);
  };

  const handleChange = (event) => {
    event.target.setCustomValidity("");
    let { name, value } = event.target;
    if (name === "fullName") {
      const regex = /^[A-Za-z\s]*$/;
      if (regex.test(value)) {
        setFormValues({ ...formValues, [name]: value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  useEffect(() => {
    if(location?.search.includes('verify=1')){
      setVerified(false);
    }
    if (phoneRef) {
      let childElement =
        phoneRef?.current?.dropdownContainerRef?.querySelector(".selected-flag");

      childElement?.setAttribute("tabindex", "-1");
    }

    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleVerifyToken = async (event) => {
    event.preventDefault();
    try {
      const response = await api.get("/auth/verify", {
        params: {
          email: !location?.search?.includes('verify=1') ? formValues.email : localStorage.getItem('tempmail'),
          token: verificationToken,
        },
      });

      if (response.status === 200) {
        setVerified(true);
        setLoginSuccess("Token Verified!!! Login Now");
        navigate("/login");
        Swal.fire({
          title: "Profile Under Review",
          text: "Your Profile is under review, please wait while the admin reviews your profile.",
          confirmButtonColor: "#0d6efd",
          // icon: "info",
        });
        // setLoginSuccess("Token verified!!! Please login again");
      } else {
        setError("Invalid token. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying token:", error);
      setError("Invalid token. Please try again.");
    }
  };

  // GOOGLE LOGIN

  const onSuccess = async (res) => {
    const userObject = jwtDecode(res.credential);
    const { email, given_name, family_name } = userObject;
    const fullName = given_name + " " + family_name;
    const user_email = email;
    const data = {
      email: user_email,
      fullName,
    };
    try {
      dispatch(isLoader(true));
      const response = await api.post("/auth/google-login", data);
      const user = response.data.user;
      const userObject = {
        userName: user?.userName,
        userId: user?.id,
        fullName: user?.fullName,
        email: user?.email,
        // password: user?.password,
        createdAt: user?.createdAt,
        updatedAt: user?.updatedAt,
        userType: user?.userType,
        referralId: user?.referral_id,
      };
      // setLoginSuccess("success");
      if (userObject?.userType == "Pending") {
        notifyError(
          "Your Profile is under review, please wait while the admin reviews your profile."
        );
      }
      if (userObject?.userType == "Blocked") {
        notifyError("Your Profile is blocked, you cannot login.");
      }
      if (userObject?.userType == "User" || userObject?.userType == "Admin") {
        setAuthToken(response.data.token);
        dispatch(setUser(userObject));
        notifySuccess("Registered Successfully!");
        navigate("/home");
      }
      dispatch(isLoader(false));
    } catch (err) {
      if (err.response) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Something went wrong!");
      }
      dispatch(isLoader(false));
    }
  };
  const onFailure = (err) => {
    console.log("failed", err);
  };

  const sourcesArr = [
    "Search Engine (Google, Bing, Yahoo)",
    "Google Ads",
    "Facebook",
    "Youtube",
    "Twitter",
    "Instagram",
    "LinkedIn",
    "Blog/Article",
    "Email",
    "Word of Mouth",
    "Others",
  ];

  const handleOnChangePhone = (value, data, event, formattedValue) => {
    if (value !== undefined) {
      setFormValues({
        ...formValues,
        phone_number: value,
        formatted_phone_number: formattedValue,
        country_code: data.countryCode,
        dial_code: "+" + data.dialCode,
      });
    }
  };

  return (
    <div>
      {verified ? (
        <>
          <div className={styles.signupContainer}>
            <h2 className={styles.heading}>Become an Affiliate</h2>
            <p
              className={styles.signupTagline}
              style={{ whiteSpace: "pre-wrap" }}
            >
              Your contact information is confidential. We won’t send you any
              spam or sell your information to any third party.
            </p>
            {/* <div className="d-flex justify-content-center mb-3">
              <GoogleLogin
                onSuccess={onSuccess}
                onError={onFailure}
                onFailure={onFailure}
              />
            </div> */}
            <form className={styles.signupForm} onSubmit={handleSubmit}>
              <div className={styles.filedRow}>
                {" "}
                <div className={styles.halfInput}>
                  <LabelCustom isReq={true} text="Full Name" />
                  <input
                    type="text"
                    maxLength={100}
                    id="fullName"
                    name="fullName"
                    value={formValues.fullName}
                    style={{ width: "100%" }}
                    onInvalid={(e) => {
                      e.target.setCustomValidity("Full Name is required");
                    }}
                    onChange={handleChange}
                    // placeholder="*Full Name"
                    // required
                  />
                </div>
                <div className={styles.halfInput}>
                  <LabelCustom isReq={true} text="Email" />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    style={{ width: "100%" }}
                    value={formValues.email}
                    onInvalid={(e) => {
                      if (e.target.value == "") {
                        e.target.setCustomValidity("Email is Required");
                      } else {
                        e.target.setCustomValidity("Email is Invalid");
                      }
                    }}
                    onChange={handleChange}
                    // placeholder="*Email"
                    // required
                  />
                </div>
              </div>
              <div className={styles.filedRow}>
                <div className={styles.halfInput}>
                  <LabelCustom isReq={true} text="Password" />
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formValues.password}
                    style={{ width: "100%" }}
                    onInvalid={(e) => {
                      e.target.setCustomValidity("Password is required");
                    }}
                    onChange={handlePasswordChange}
                    // placeholder="*Password"
                    // required
                  />
                </div>
                <div className={styles.halfInput}>
                  <LabelCustom isReq={true} text="Phone Number" />
                  <PhoneInput
                    ref={phoneRef}
                    country={formValues.country_code}
                    // countryCodeEditable={false}
                    placeholder="*Enter phone number"
                    value={formValues.formatted_phone_number}
                    onChange={handleOnChangePhone}
                    containerClass={styles["custom-phone-input"]}
                    inputClass={styles["custom-phone-input"]}
                    buttonClass={styles["custom-phone-btn"]}
                    buttonStyle={{
                      backgroundColor: "#fff",
                    }}
                    inputProps={{
                      name: "phone",
                      // required: true,
                      // autoFocus: true,
                    }}
                    // required={true}
                  />
                </div>
              </div>

              <div className={styles.filedRow}>
                <div className={styles.halfInput}>
                  <LabelCustom isReq={true} text="Where did you find us?" />
                  <select
                    id="how_many_resources_do_you_need_-input"
                    className="hs-form__field__input"
                    name="team_size"
                    // required={true}
                    style={{
                      // marginTop: "1.5rem",
                      borderRadius: "100px",
                      padding: "10px 1rem",
                      background: "#fff",
                    }}
                    aria-invalid="false"
                    aria-describedby="how_many_resources_do_you_need_-description"
                    aria-required="true"
                    value={formValues.source}
                    // onChange={handleInputChange}
                    onInvalid={(e) => {
                      let selected = e.target.value;
                      if (selected !== "Others") {
                        e.target.setCustomValidity(
                          "Where did you find us? is required"
                        );
                      }
                    }}
                    onChange={(e) => {
                      e.target.setCustomValidity("");
                      let selected = e.target.value;
                      if (selected === "Others") {
                        setOthers(true);
                        setFormValues({ ...formValues, source: "Others" });
                      } else {
                        setOthers(false);
                        setFormValues({ ...formValues, source: selected });
                      }
                    }}
                  >
                    <option label="Please Select" disabled="" value="">
                      Select Source
                    </option>
                    {sourcesArr.map((item, index) => (
                      <option key={index} value={item}>
                        {item} &nbsp;&nbsp;&nbsp;&nbsp;
                      </option>
                    ))}
                  </select>
                </div>
                {others && (
                  <div className={styles.halfInput}>
                    <LabelCustom isReq={true} text="Other Source" />
                    <input
                      style={{ width: "100%", marginBottom: "0" }}
                      type="text"
                      maxLength={100}
                      id="text-source"
                      name="source"
                      value={formValues.othersource}
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Others is required")
                      }
                      onChange={(e) => {
                        e.target.setCustomValidity("");
                        setFormValues({
                          ...formValues,
                          othersource: e.target.value,
                        });
                      }}
                      placeholder=""
                      // required={true}
                    />
                  </div>
                )}
                {!others && (
                  <div className={styles.halfInput}>
                    <LabelCustom isReq={true} text="Country" />
                    <select
                      id="how_many_resources_do_you_need_-input"
                      className="hs-form__field__input"
                      name="team_size"
                      // required={true}
                      style={{
                        // marginTop: !others ? "1rem" : "0",
                        borderRadius: "100px",
                        padding: "10px 1rem",
                        background: "#fff",
                      }}
                      aria-invalid="false"
                      aria-describedby="how_many_resources_do_you_need_-description"
                      aria-required="true"
                      value={formValues.country}
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Country is required")
                      }
                      // onChange={handleInputChange}
                      onChange={(e) => {
                        e.target.setCustomValidity("");
                        let selected = e.target.value;
                        setFormValues({ ...formValues, country: selected });
                      }}
                    >
                      <option label="Please Select" disabled="" value="">
                        Select Country
                      </option>
                      {countryList.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>

              {others && (
                <div style={{ marginTop: "1rem" }}>
                  <LabelCustom isReq={true} text="Country" />
                  <select
                    id="how_many_resources_do_you_need_-input"
                    className="hs-form__field__input"
                    name="team_size"
                    // required={true}
                    style={{
                      // marginTop: !others ? "1rem" : "0",
                      borderRadius: "100px",
                      padding: "10px 1rem",
                      background: "#fff",
                    }}
                    aria-invalid="false"
                    aria-describedby="how_many_resources_do_you_need_-description"
                    aria-required="true"
                    value={formValues.country}
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Country is required")
                    }
                    // onChange={handleInputChange}
                    onChange={(e) => {
                      e.target.setCustomValidity("");
                      let selected = e.target.value;
                      setFormValues({ ...formValues, country: selected });
                    }}
                  >
                    <option label="Please Select" disabled="" value="">
                      Select Country
                    </option>
                    {countryList.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div style={{ marginTop: "1rem" }}></div>
              <LabelCustom isReq={false} text="Linkedin URL (Optional)" />
              <input
                // style={{ marginTop: "1rem" }}
                type="text"
                maxLength={100}
                id="text-source"
                name="source"
                value={formValues.linkedin}
                onChange={(e) =>
                  setFormValues({ ...formValues, linkedin: e.target.value })
                }
                // placeholder="Linkedin URL"
                // required
              />

              <button
                type="submit"
                style={{ marginTop: "0.2rem" }}
                className={`${styles.signupButton} ${
                  isRegistering ? styles.pleaseWait : ""
                }`}
                disabled={isRegistering}
              >
                {isRegistering ? "Please wait..." : "Register"}
              </button>
            </form>
            {loginSuccess && (
              <Snackbar open={true} autoHideDuration={3000}>
                <Alert severity="success">
                  {loginSuccess}, {formValues.firstName}!
                </Alert>
              </Snackbar>
            )}
            {error && (
              <Snackbar open={true} autoHideDuration={3000}>
                <Alert severity="error">{error}</Alert>
              </Snackbar>
            )}
            <p className={styles.signupLink}>
              Already have an account? <Link to="/login">Sign In</Link>
            </p>
            <p className={styles.signupLink}>
              <Link to="/terms-and-conditions/" target="_blank">
                Terms and conditions
              </Link>
            </p>
          </div>
        </>
      ) : (
        <>
          <div className={styles.container}>
            <form className={styles.form} onSubmit={handleVerifyToken}>
              <h2 className={styles.heading}>Email Verification</h2>
              <div className={styles.formGroup}>
                <label htmlFor="verificationToken">
                  Enter Token received on {!location?.search?.includes('verify=1') ? formValues.email : localStorage.getItem('tempmail')}:
                </label>
                <input
                  type="text"
                  maxLength={100}
                  id="verificationToken"
                  value={verificationToken}
                  onChange={(e) => setVerificationToken(e.target.value)}
                />
              </div>
              <div className={styles.buttonContainer}>
                <button className={styles.button} type="submit">
                  Verify Token
                </button>
              </div>
            </form>
            {error && (
              <Snackbar open={true} autoHideDuration={3000}>
                <Alert severity="error">{error}</Alert>
              </Snackbar>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Signup;
