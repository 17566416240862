import React from "react";
import styles from "./Works.module.css";

const Works = () => {
  return (
    <div className={styles.works}>
      <div className={styles.worksContainer}>
        <h1
          className={styles.heading}
          data-aos="fade-down"
          data-aos-duration="500"
        >
          Ensuring a Seamless Affiliate Journey
        </h1>
        <p
          style={{
            fontWeight: "normal",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {" "}
          From Start to Finish
        </p>
        <br />
        <div className={styles.wrapper}>
          <div
            className={styles.card1}
            data-aos="flip-left"
            data-aos-duration="500"
            data-aos-delay="400"
          >
            <div>
              <img src="img/Apply.svg" alt="Loading" width="100" height="62" />
            </div>
            <br></br>
            <div>
              <h2>Apply</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              Begin your affiliate journey by registering for our Data
              Engineering Staff Augmentation Affiliate Program and start earning
              attractive commissions.
            </div>
          </div>
          <div
            className={styles.card2}
            data-aos="flip-left"
            data-aos-duration="500"
            data-aos-delay="400"
          >
            <div>
              <img
                src="img/Promote.svg"
                alt="Loading"
                width="100"
                height="62"
              />
            </div>
            <br></br>
            <div>
              <h2>Promote</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              Market our team augmentation services and other products using
              your affiliate links in blogs, articles, email newsletters, banner
              ads, and various digital content channels.
            </div>
          </div>
          <div
            className={styles.card3}
            data-aos="flip-left"
            data-aos-duration="500"
            data-aos-delay="400"
          >
            <div>
              <img src="img/Earn.svg" alt="Loading" width="100" height="62" />
            </div>
            <br></br>
            <div>
              <h2>Earn</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              Generate earnings by successfully referring qualified leads to our
              affiliate program. You'll then receive a set commission when your
              referrals find value in the services and solutions offered.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
