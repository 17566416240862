import React from "react";
import Notfound from "../../../commonComponents/Notfound";
import moment from "moment-timezone";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "IST",
  });
};

const TransactionTab = ({ transections, style }) => {
  if (transections == null) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loading"></div>
      </div>
    );
  }
  return (
    <div style={style}>
      {transections?.length !== 0 ? (
        <table className="styled-table" style={{ boxShadow: "none" }}>
          <thead>
            <tr>
              {/* <th>ID</th>
              <th>User Email</th> */}
              <th style={{ textAlign: "center" }}>Transaction ID</th>
              <th style={{ textAlign: "center" }}>Paid By</th>
              <th style={{ textAlign: "center" }}>Amount</th>
              <th style={{ textAlign: "center" }}>Paid On</th>
              {/* <th>Reserved Column</th> */}
            </tr>
          </thead>
          <tbody>
            {transections?.map((transection, i) => (
              <tr key={transection.id} style={{ textAlign: "center" }}>
                {/* <td> */}
                {/* {transection.id} */}
                {/* <Link to={`/profile/${transection.user_id}`}>
                      {transection.id}
                    </Link> */}
                {/* </td> */}
                {/* <td>{transection.affiliate_User["email"]} </td> */}
                <td>{transection.transaction_id}</td>
                <td>{transection.paid_by}</td>
                <td>${transection.amount}</td>
                <td>{moment(transection.transaction_time).local().format("LL LT")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Notfound text="No Transactions Found" />
      )}
    </div>
  );
};

export default TransactionTab;
