import { notifyError, } from './Toaster/Toaster';

const validationCheck = (data) => {
    for (let key in data) {
        if (!data[key]) {
            return { status: false, errorAt: key };
        }
    }
    return { status: true };
};

const validate = (data) => {
    const validation = validationCheck(data);
    if (!validation.status) {
        notifyError(`Please provide a ${validation?.errorAt}`);
        return false;
    } else {
        return true
    }
}

export {
    validate
}

