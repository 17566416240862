import { notifyError,notifySuccess } from "../components/util/Toaster/Toaster";
import { api, setAuthToken } from "../Api";

const unsubscribeEmail = async (body) => {
    const storedToken = localStorage.getItem("loggedUserToken");
    if (storedToken) {
        setAuthToken(storedToken);
        try {
            const response = await api.post("/unsubscribe", body);
            if (response.status === 200) {
                notifySuccess(response.data.message)
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                notifyError(error.response.data.message)
            } else if (error.response) {
                notifyError(error.response.data.message)
            } else {
                notifyError("Something went wrong");
            }
        } finally {
        }
    } else {
        notifyError("Session Expired please login again.")
    }
};

export {
    unsubscribeEmail
}