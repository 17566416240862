import React, { useState } from "react";
import Button from "../../util/Button/Button";
import "./InfoPage.css";
import { Link } from "react-router-dom";
import VideoPopup from "./../../common/videoPopup";

const InfoPage = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <section>
      <div className="container-cls">
        <div className="imageContainer-cls">
          <img
            className="cls-img "
            src="img/placeholder_image.png"
            onClick={openPopup}
          ></img>
        </div>
        <div className="contentContainer-cls">
          <div className="infoPage">
            {/* <h3
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              Affiliate Program for Data Engineering Team Augmentation Services
            </h3> */}
            <span className="head-text">
              Affiliate Program for Data Engineering Team Augmentation Services
            </span>
            <h4
              // data-aos="fade-left"
              // data-aos-duration="1000"
              // data-aos-delay="400"
              className="desc"
              style={{fontSize:"15px"}}
            >
              Join our Data Engineering Staff Augmentation Affiliate Program and
              turn your online presence into a lifetime revenue stream.
            </h4>
          </div>

          <div
            data-aos="fade-down"
            data-aos-duration="500"
            style={{ paddingBottom: "1rem" }}
          >
            <Button></Button>
          </div>

          <div
            className="login-at-end"
            data-aos="fade-up"
            data-aos-duration="500"
            style={{
              paddingBottom: "3rem",
              marginLeft: "14px",
              fontSize: "14px",
            }}
          >
            Already an affiliate?{" "}
            <Link to="/login" style={{ fontWeight: 500 }}>
              Login
            </Link>
          </div>
        </div>
      </div>

      <div>
        {isPopupOpen && (
          <VideoPopup
            videoUrl="videos/affiliate_program_team_augmentation_datatobiz.mp4"
            isOpen={isPopupOpen}
            onRequestClose={closePopup}
          />
        )}
      </div>
    </section>
  );
};

export default InfoPage;
