import React, { useState, useEffect } from "react";
import styles from "./Header.module.css";
import BlockedUser from "../../HomePage/UserProfile/BlockedUser";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faSackDollar,
  faAddressCard,
  faCircleQuestion,
  faPeopleCarryBox,
  faLightbulb,
  faSignal,
  faChevronDown,
  faMoneyCheckDollar,
  faScrewdriverWrench,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { setUser } from "../../../Redux/actions/userActions";

const Header = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false); // State to manage drawer visibility
  const loggedUser = useSelector((state) => state.user.loggedUser);
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // const token = localStorage.getItem("loggedUserToken");
    if (loggedUser) {
      setIsLoggedIn(true);
      toggleDrawer();
    } else {
      setIsLoggedIn(false);
    }
  }, [loggedUser]); // Add loggedUser as a dependency

  const toggleDrawer = () => {
    setShowDrawer((prevState) => !prevState);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("loggedUserToken");
    dispatch(setUser(null));
    // Add additional logic for logout if needed
  };

  const handleLogin = () => {
    // Add additional logic for login if needed
    window.location.href="/login"
  };

  const getFirstName = () => {
    let fullName = props.user?.fullName;
    const first = fullName?.split(" ").at(0);
    return first;
  };

  const handleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  return (
    <nav className={styles.nav}>
      <div className={styles.logo}>
        <Link to="/">
          <img
            src={`${process.env.REACT_APP_PROJECT_URL}/img/datatobiz-logo.svg`}
            alt="Logo"
          />
        </Link>
      </div>
      <div className={styles.hamburger} onClick={toggleDrawer}>
        <div
          className={`${styles.hamburgerLine} ${
            showDrawer ? styles.active : ""
          }`}
        />
        <div
          className={`${styles.hamburgerLine} ${
            showDrawer ? styles.active : ""
          }`}
        />
        <div
          className={`${styles.hamburgerLine} ${
            showDrawer ? styles.active : ""
          }`}
        />
      </div>
      <ul
        className={`${styles.buttons} ${showDrawer ? styles.showDrawer : ""}`}
      >
        {isLoggedIn ? (
          <>
            <li>
              <div
                className={`${styles.dropdownContainer} ${
                  showDropdown ? styles.showDropdown : ""
                }`}
                onMouseEnter={handleDropdown}
                onMouseLeave={handleDropdown}
              >
                {loggedUser?.userType !== "Admin" && (
                  <Link to="/payouts">
                    <span className={`${styles.text} ${styles.earnLink}`}>
                      Payouts
                    </span>
                  </Link>
                )}
              </div>
            </li>
            <li>
              {" "}
              <Link to="/">
                <span className={`${styles.text}`}>Dashboard</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/home" className={`${styles.text} ${styles.earnLink}`}>
                <FontAwesomeIcon icon={faHouse} className={`${styles.icon} `} />
                Home
              </Link>
            </li> */}
            {loggedUser?.userType === "Pending" ? (
              <></>
            ) : loggedUser?.userType === "User" ? (
              <>
                <></>
              </>
            ) : loggedUser?.userType === "Blocked" ? (
              <>
                <BlockedUser />
              </>
            ) : (
              <>
                <li>
                  <div
                    className={`${styles.dropdownContainer} ${
                      showDropdown ? styles.showDropdown : ""
                    }`}
                    onMouseEnter={handleDropdown}
                    onMouseLeave={handleDropdown}
                  >
                    <span className={`${styles.text} ${styles.earnLink}`}>
                      {/* <FontAwesomeIcon
                        icon={faScrewdriverWrench}
                        className={`${styles.icon} `}
                      /> */}
                      Admin Panel
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={`${styles.icon} `}
                      />
                    </span>
                    <div className={styles.dropdownContent}>
                      <Link to="/affiliate">
                        {/* <FontAwesomeIcon
                          icon={faPeopleCarryBox}
                          className={`${styles.icon} `}
                        /> */}
                        Affiliate Users
                      </Link>
                      <Link to="/transactions">
                        {/* <FontAwesomeIcon
                          icon={faMoneyCheckDollar}
                          className={`${styles.icon} `}
                        /> */}
                        Transactions
                      </Link>
                      <Link to="/upcoming-transactions">
                        Upcoming Transactions
                      </Link>
                    </div>
                  </div>
                </li>
                {/* <li>
                  <Link to="/affiliate">
                    <span className={`${styles.text} ${styles.usernameLink}`}>
                      <FontAwesomeIcon
                        icon={faPeopleCarryBox}
                        className={`${styles.icon} `}
                      />
                      Affiliate Users
                    </span>
                  </Link>
                </li> */}
              </>
            )}
            <li>
              <div
                className={`${styles.dropdownContainer} ${
                  showDropdown ? styles.showDropdown : ""
                }`}
                // onMouseEnter={handleDropdown}
                // onMouseLeave={handleDropdown}
              >
                {loggedUser?.userType !== "Admin" && (
                  <Link to="/referrals" className={`${styles.startReferring}`}>
                    <span
                      className={`${styles.earnLink}`}
                      style={{ color: "#ffff" }}
                    >
                      Start Referring
                    </span>
                  </Link>

                  // <span className={`${styles.text} ${styles.earnLink}`}>
                  //   Refer and Earn
                  //   <FontAwesomeIcon
                  //     icon={faChevronDown}
                  //     className={`${styles.icon} `}
                  //   />
                  // </span>
                )}

                {/* <div className={styles.dropdownContent}>
                  <Link to="/products">
                    Products
                  </Link>
                  <Link to="/referrals">
                    Submit your referral details
                  </Link>
                </div> */}
              </div>
            </li>
            <li  style={{marginLeft: loggedUser?.userType === "Admin" && "0px"}}>
              <div
                className={`${styles.dropdownContainer} ${
                  showDropdown ? styles.showDropdown : ""
                }`}
                onMouseEnter={handleDropdown}
                onMouseLeave={handleDropdown}
              >
                <span className={`${styles.text} ${styles.earnLink}`}>
                  <FontAwesomeIcon
                    icon={faUser}
                    className={`${styles.icon} `}
                  />
                  {/* {getFirstName()} */}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={`${styles.icon} `}
                  />
                </span>
                <div className={styles.dropdownContent}>
                  <Link
                    to={`/#`}
                    onClick={() => window.location.href=`/profile`}
                  >
                    <span className={`${styles.text} ${styles.usernameLink}`}>
                      {getFirstName()}
                    </span>
                  </Link>
                  {loggedUser?.userType === "Admin" && (
                    <Link to="/cc-emails">
                      <span className={`${styles.text} ${styles.usernameLink}`}>
                        Add Recipients
                      </span>
                    </Link>
                  )}
                  <Link to="/" onClick={handleLogout}>
                    <span
                      style={{ color: "#126AFF", fontWeight: "bolder" }}
                      className={`${styles.text} ${styles.usernameLink}`}
                    >
                      Log Out
                    </span>
                  </Link>
                </div>
              </div>
            </li>
            {/* <li>
              <Link to="/help" className={styles.text}>
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  className={`${styles.icon} `}
                />
                Help
              </Link>
            </li> */}
          </>
        ) : (
          <>
            <li className={styles.hideOnLogin}>
              {/* <Link to="/login"> */}
                <button
                  className={`${styles.btn} ${styles.btn1}`}
                  onClick={handleLogin}
                >
                  Login
                </button>
              {/* </Link> */}
            </li>
            <li className={styles.hideOnLogin}>
              <Link to="/register">
                <button className={`${styles.btn} ${styles.btn2}`}>
                  Join Now
                </button>
              </Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Header;
