import axios from "axios";
// require('dotenv').config();

const API_URL = process.env.REACT_APP_API_URL
// const API_URL = "https://stagingapi.affiliate.datatobiz.com"

const api = axios.create({
  baseURL: API_URL, // Replace with your backend API URL
});

// Function to set the token in the request headers
const setAuthToken = (token) => {
  if (token) {
    // Apply the token to every request header
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    localStorage.setItem("loggedUserToken", token); // Store the token in local storage
  } else {
    // Remove the token from the request headers
    delete api.defaults.headers.common["Authorization"];
    localStorage.removeItem("loggedUserToken"); // Remove the token from local storage
  }
};

export { api, setAuthToken };
