import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import ReferralForm from "../../common/referral_form";
import "./ReferralPage.css";
import VideoPopup from "./../../common/videoPopup";
import Footer from "./../../common/internalFooter";

const Referrals = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    let ref_popup_open = localStorage.getItem("total_referrals");
    if (ref_popup_open == 0) {
      setShowVideo(true);
    }
  }, []);

  return (
    <div>
      <div className="container-main-ref-form">
        {showVideo && (
          <div className="container-main-left">
            <h1
              className="left-margin-ref title-h1-ref"
              style={{
                fontSize: "42px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Promote and Refer{" "}
              <img
                style={{
                  // position: "absolute", left: "48%",
                  zIndex: 1,
                  marginRight:'1rem'
                }}
                src="img/arrowaffiliate.svg"
                alt="Loading"
                width="100"
                height="100"
              />
            </h1>
            <span style={{ lineHeight: "30px" }} className="left-margin-ref">
              As a valued affiliate of the DataToBiz Data Engineering
              Augmentation Affiliate Program, you'll have the opportunity to
              promote and refer clients to a range of scalable staffing
              solutions.
            </span>
            {true && (
              <img
                // className="cls-img"
                className="left-margin-ref"
                alt="img"
                style={{ width: "68%", cursor: "pointer" }}
                src="img/placeholder_image.png"
                onClick={openPopup}
              ></img>
            )}
            <div className="fold-2 left-margin-ref">
              <div>
                <h3>How do you get paid for your referrals?</h3>
                <span>
                  DataToBiz offers generous yet easy commission architecture
                </span>
              </div>

              <div className="foldtextbox">
                <div className="">
                  <p>5% Commission* of 1st month revenue</p>
                  <span style={{ fontSize: "14px" }}>
                    One-time only under min. 3 months contract duration
                  </span>
                </div>
                <div className="">
                  <p>Net60 Payment Structure</p>
                  <span style={{ fontSize: "14px" }}>
                    Hassle-free payout within 60 calendar days
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="container-main-right">
          {" "}
          <ReferralForm showVideo={showVideo} />
        </div>
      </div>
      {/* <div className="fold-1">
        <div className="fold-1-image-container">
        
        </div>

        <div className="fold-1-content-Container">
         
        </div>
      </div> */}

      <div>
        {isPopupOpen && (
          <VideoPopup
            videoUrl="videos/affiliate_program_team_augmentation_datatobiz.mp4"
            isOpen={isPopupOpen}
            onRequestClose={closePopup}
          />
        )}
      </div>
      {!showVideo && (
        <div
          className="fold-2-new"
          style={{ textAlign: "center", margin: "2rem 0" }}
        >
          <div>
            <h3 style={{ fontWeight: "700" }}>
              How do you get paid for your referrals?
            </h3>
            <span>
              DataToBiz offers generous yet easy commission architecture
            </span>
          </div>
          <br />
          <div className="fold-2-sub-container">
            <div className="fold-2-boxes">
              <p>5% Commission* of 1st month revenue</p>
              <span style={{ fontSize: "14px" }}>
                One-time only under min. 3 months contract duration
              </span>
            </div>
            <div className="fold-2-boxes">
              <p>Net60 Payment Structure</p>
              <span style={{ fontSize: "14px" }}>
                Hassle-free payout within 60 calendar days
              </span>
            </div>
          </div>
        </div>
      )}

      <Footer></Footer>
    </div>
  );
};

export default Referrals;
