import React from 'react'

function CustomLoader({isLoading}) {
    if (isLoading) {
        return (
          <div className="loading-container">
            <div className="loading"></div>
            <p>Loading...</p>
          </div>
        ); // Display loading message
      }
}

export default CustomLoader