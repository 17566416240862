import React, { useState } from "react";
import ReactPlayer from "react-player";
import Modal from "react-modal";
import "./videoPopUp.css";

const VideoPopup = ({ videoUrl, isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Video Player"
      className="video-modal"
    >
      <button onClick={onRequestClose} className="close-button">
        <span>&times;</span>
      </button>
      <ReactPlayer
        url={videoUrl}
        playing={true} // Set playing to true to enable autoplay
        controls={true}
        // height="30vh"
      />
    </Modal>
  );
};

export default VideoPopup;
