import React from "react";
import "./termscondition.css";
import { Link } from "react-router-dom";
import Footer from "../../util/Footer/Footer";
const TermsCondition = () => {
  return (
    <>
      <div className="main-tc">
        <div className="container-tc">
          <p className="para" style={{ textAlign: "center" }}>
            Last updated: 1st Sep 2023
          </p>
          <h2 className="title">Terms and Conditions</h2>
          <p className="para">
            By accessing this Website, accessible from
            https://affiliate.datatobiz.com/, you are agreeing to be bound by
            these Website Terms and Conditions of Use and agree that you are
            responsible for the agreement with any applicable local laws. If you
            disagree with any of these terms, you are prohibited from accessing
            this site. The materials contained in this Website are protected by
            copyright and trademark law.
          </p>
          <h3 className="title2">Use a License</h3>
          <p className="para">
            Permission is granted to temporarily download one copy of the
            materials on DataToBiz’s Website for personal, non-commercial
            transitory viewing only. This is the grant of a license, not a
            transfer of title, and under this license, you may not:
            <p className="para-list">
              <div className="circle"></div>modify or copy the materials;
            </p>
            <div className="para-list">
              <div className="circle"></div>
              use the materials for any commercial purpose or for any public
              display;
            </div>
            <div className="para-list">
              <div className="circle"></div>
              attempt to reverse engineer any software contained on DataToBiz’s
              Website;
            </div>
            <div className="para-list">
              <div className="circle"></div>
              remove any copyright or other proprietary notations from the
              materials; or
            </div>
            <div className="para-list">
              <div className="circle"></div>
              transferring the materials to another person or “mirroring” the
              materials on any other server.
            </div>
            <p className="para-list">
              This will let DataToBiz terminate upon violations of any of these
              restrictions. Upon termination, your viewing right will also be
              terminated and you should destroy any downloaded materials in your
              possession whether it is printed or electronic format.
            </p>
          </p>

          <h3 className="title2">Disclaimer</h3>
          <p className="para">
            All the materials on DataToBiz’s Website are provided “as is”.
            DataToBiz makes no warranties, may it be expressed or implied, and
            therefore negates all other warranties. Furthermore, DataToBiz does
            not make any representations concerning the accuracy or reliability
            of the use of the materials on its Website or otherwise relating to
            such materials or any sites linked to this Website.
          </p>
          <p className="para">
            Read the full Disclaimer{" "}
            <Link to="https://www.datatobiz.com/disclaimer-for-datatobiz/">
              here.
            </Link>
          </p>

          <h3 className="title2">Cookies</h3>
          <p className="para">
            We employ the use of cookies. By accessing DataToBiz Affiliate
            Partner Platform, you agreed to use cookies in agreement with
            DataToBiz’s Privacy Policy.
          </p>
          <p className="para">
            Most interactive websites use cookies to let us retrieve the user’s
            details for each visit. Cookies are used by our website to enable
            the functionality of certain areas to make it easier for people
            visiting our website. Some of our affiliate/advertising partners may
            also use cookies.
          </p>

          <h3 className="title2">Affiliate Commissions</h3>
          <span>DataToBiz Data Engineering Team Augmentation Services</span>

          <span>
            We offer a commission structure designed to benefit our affiliates.
            With our program:
          </span>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              Affiliates can earn a one-time commission of 5% on the first
              month's revenue for clients who commit to a minimum contract
              duration of 3 months, once the resource is onboarded.
            </li>
          </ul>

          {/* <table id="customers" className="styled-table">
            <thead>
              <tr>
                <th>Sr.No.</th>
                <th>No. of referrals</th>
                <th>Geography</th>
                <th>Commission in USD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Upto 5</td>
                <td>NA/Eu/Au</td>
                <td>100</td>
              </tr>
              <tr>
                <td>2</td>
                <td>6-10</td>
                <td>NA/Eu/Au</td>
                <td>150</td>
              </tr>
              <tr>
                <td>3</td>
                <td>10-15</td>
                <td>NA/Eu/Au</td>
                <td>200</td>
              </tr>
              <tr>
                <td>4</td>
                <td>More than 15</td>
                <td>NA/Eu/Au</td>
                <td>250</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Upto 5</td>
                <td>APAC/MENA</td>
                <td>75</td>{" "}
              </tr>
              <tr>
                <td>6</td>
                <td>6-10</td>
                <td>APAC/MENA</td>
                <td>100</td>{" "}
              </tr>

              <tr>
                <td>7</td>
                <td>10-15</td>
                <td>APAC/MENA</td>
                <td>150</td>
              </tr>
              <tr>
                <td>8</td>
                <td>More than 15</td>
                <td>APAC/MENA</td>
                <td>200</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Upto 5</td>
                <td>Rest of the world</td>
                <td>50</td>
              </tr>
              <tr>
                <td>10</td>
                <td>6-10</td>
                <td>Rest of the world</td>
                <td>75</td>
              </tr>
              <tr>
                <td>11</td>
                <td>10-15</td>
                <td>Rest of the world</td>
                <td>100</td>
              </tr>
              <tr>
                <td>12</td>
                <td>More than 15</td>
                <td>Rest of the world</td>
                <td>15</td>
              </tr>
              <tr>
                <td>13</td>
                <td>When a user refers a client and the client is booked</td>
                <td>All geographies</td>
                <td>10% of ticket size</td>
              </tr>
              <tr>
                <td>14</td>
                <td>When a user refers a client and the client is booked</td>
                <td>All geographies</td>
                <td>15% of ticket size</td>
              </tr>
              <tr>
                <td>15</td>
                <td>When a user refers a client and the client is booked</td>
                <td>All geographies</td>
                <td>20% of ticket size</td>
              </tr>
            </tbody>
          </table> */}
          <div>
            <p className="para-italic">Note:</p>
            <p className="para-italic">
              {" "}
              <div className="circle"></div>
              Here referrals are the clients referred
            </p>
            <p className="para-italic">
              {" "}
              <div className="circle"></div>
              Logic Type in case of referrals: Qualified Lead (as per screening
              by DataToBiz’s business team)
            </p>
            <p className="para-italic">
              {" "}
              <div className="circle"></div>
              Logic Type for Sno. 13, 14, 15: Client is booked
            </p>
          </div>

          {/* <p className="para-italic">DataToBiz Products: PrepAI</p>
          <table className="styled-table">
            <thead>
              <tr>
                <th>Sr.No.</th>
                <th>No. of Paid Subscribers</th>
                <th>Subscription Plan</th>
                <th>Commission % (One Time)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Upto 10</td>
                <td>Monthly/Quarterly/Annually</td>
                <td>20%</td>
              </tr>
              <tr>
                <td>2</td>
                <td>10-20</td>
                <td>Monthly/Quarterly/Annually</td>
                <td>25%</td>
              </tr>
              <tr>
                <td>3</td>
                <td>20-30</td>
                <td>Monthly/Quarterly/Annually</td>
                <td>30%</td>
              </tr>
              <tr>
                <td>4</td>
                <td>More than 30</td>
                <td>Monthly/Quarterly/Annually</td>
                <td>35%</td>
              </tr>
              <tr>
                <td>5</td>
                <td>5 B2B customers those who convert</td>
                <td>Custom/API</td>
                <td>10%</td>{" "}
              </tr>
              <tr>
                <td>6</td>
                <td>5 B2B customers those who convert</td>
                <td>Custom/API</td>
                <td>20%</td>{" "}
              </tr>

              <tr>
                <td>7</td>
                <td>5 qualified customers who convert</td>
                <td>Custom/API</td>
                <td>20%</td>
              </tr>
              <tr>
                <td>8</td>
                <td>5 qualified customers who convert</td>
                <td>Custom/API</td>
                <td>30%</td>
              </tr>
            </tbody>
          </table> */}

          <h3 className="title2">Commission Rate Changes</h3>
          <p className="para">
            DataToBiz reserves the right to modify the commission rates for
            affiliates at its discretion. By participating in the affiliate
            program, you agree that you are bound by the commission rate changes
            made by DataToBiz.
          </p>
          <h3 className="title2">Dispute Resolution</h3>
          <p className="para">
            In the event of any disputes arising between affiliates and
            DataToBiz, both parties agree to engage in negotiations to resolve
            the matter. However, if an agreement cannot be reached, DataToBiz
            holds the final decision-making authority. You hereby acknowledge
            and consent that no legal action can be taken against DataToBiz
            regarding commission rate changes or dispute resolution outcomes.
          </p>
          <p className="para">
            By continuing to participate in the affiliate program, you confirm
            your acceptance of the terms stated above.
          </p>
          <h3 className="title2">Limitations</h3>
          <p className="para">
            DataToBiz or its suppliers will not be held accountable for any
            damages that will arise with the use or inability to use the
            materials on DataToBiz’s Website, even if DataToBiz or an authorized
            representative of this Website has been notified, orally or written,
            of the possibility of such damage. Some jurisdiction does not allow
            limitations on implied warranties or limitations of liability for
            incidental damages, these limitations may not apply to you.
          </p>

          <h3 className="title2">Revisions and Errata</h3>
          <p className="para">
            The materials appearing on DataToBiz’s Website may include
            technical, typographical, or photographic errors. DataToBiz will not
            promise that any of the materials on this Website are accurate,
            complete, or current. DataToBiz may change the materials contained
            on its Website at any time without notice. DataToBiz does not make
            any commitment to update the materials.
          </p>

          <h3 className="title2">Links</h3>
          <p className="para">
            DataToBiz has not reviewed all of the sites linked to its Website
            and is not responsible for the contents of any such linked site. The
            presence of any link does not imply endorsement by DataToBiz of the
            site. The use of any linked website is at the user’s own risk.
          </p>

          <h3 className="title2">Privacy Policy</h3>

          <p className="para">
            Read DataToBiz Privacy Policy{" "}
            <Link to="https://www.datatobiz.com/privacy-policy-for-datatobiz/">
              here.
            </Link>
          </p>
          <h3 className="title2">Site Terms of Use Modifications</h3>
          <p className="para">
            DataToBiz may revise these Terms of Use for its Website at any time
            without prior notice. By using this Website, you are agreeing to be
            bound by the current version of these Terms and Conditions of Use.
          </p>

          <h3 className="title2">Governing Law</h3>
          <p className="para">
            Any claim about DataToBiz’s website will be governed by local laws,
            regardless of any conflicting laws.
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer hideCTA={true} />
    </>
  );
};

export default TermsCondition;
