import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Testimonial.css";

const Testimonial = () => {
  return (
    <div className="t-container">
      <h2
        // data-aos="fade-right"
        // data-aos-duration="1000"
        // data-aos-delay="400"
        className="heading"
      >
        Affiliate partners’ stories
      </h2>
      <h5
        // data-aos="fade-left"
        // data-aos-duration="1000"
        // data-aos-delay="400"
        className="desc"
        style={{
          fontSize:'20px !important',
          textAlign: "center",
          marginBottom: "28px",
        }}
      >
        Hear what our Affiliate partners have to say about our referral program
      </h5>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
        showIndicators={false}
        // className="carousel-root"
      >
        <div className="myCarousel">
          <div className="card">
            <h3>Alia </h3>
            <h5>
              <i>Affiliate Partner, India</i>
            </h5>
            <span style={{ fontSize: "15px" }}>
              "With the datatobiz affiliate program, It's so simple to refer
              clients, and the commissions are fantastic and easy. I'm earning
              while helping others. The commission-sharing model is quite good
              for my liking. Highly recommended!"
            </span>
          </div>
        </div>

        <div>
          <div className="myCarousel">
            <div className="card">
              <h3>James</h3>
              <h5>
                <i>Content Freelancer, New York</i>
              </h5>
              <span style={{ fontSize: "15px" }}>
                Have been associated with them for the past couple of months.
                This affiliate portal is user-friendly and has allowed me to
                earn extra bucks, payouts are timely. The support from their
                team is incredible. If you have a solid to mediocre online
                presence, this program is a must-try.
              </span>
            </div>
          </div>
        </div>

        <div>
          <div className="myCarousel">
            <div className="card">
              <h3>Tom</h3>
              <h5>
                <i>Marketing Expert, London</i>
              </h5>
              <span style={{ fontSize: "15px" }}>
                "Kudos to their team for making it easy yet transparent. I am
                using other platforms too but this one always floats above my
                list. It's a win-win for both me and my referrals! Referred
                clients are satisfied which is again another win for me. Wishes
                to the Datatobiz team for keeping the same in the future too"
              </span>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Testimonial;
