import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./HomePage.module.css";
import ReferralsComponent from "./ReferralsComponent/ReferralsComponent";
import UserPending from "./UserProfile/UserPending";
import User from "./UserProfile/User";
import BlockedUser from "./UserProfile/BlockedUser";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../common/userFooter";
const HomePage = () => {
  const loggedUser = useSelector((state) => state.user.loggedUser);

  const dispatch = useDispatch();
  return (
    <>
      <div className={styles.container}>
        {loggedUser.userType === "Pending" ? (
          <UserPending />
        ) : loggedUser.userType === "User" ? (
          <>
            <User id={loggedUser.userId} />
          </>
        ) : loggedUser.userType === "Blocked" ? (
          <>
            <BlockedUser />
          </>
        ) : (
          <>
            <ReferralsComponent></ReferralsComponent>
          </>
        )}
      </div>
      {loggedUser.userType ==='User' && <Footer></Footer>}
    </>
  );
};

export default HomePage;
