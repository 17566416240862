import { useEffect, useState } from "react";
import { api, setAuthToken } from "../../../Api";
import { notifyError, notifySuccess } from "../../util/Toaster/Toaster";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setUser } from "../../../Redux/actions/userActions";
import Notfound from "../../../commonComponents/Notfound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faG } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Button,
  Modal,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "@material-ui/core/styles";
import moment from "moment";
import Swal from "sweetalert2";
import Select from "../../common/Select";

const STATUS = [
  "Pending",
  "Not Qualified",
  "ATC 1 (Attempted to contact)",
  "ATC 2 (Attempted to contact)",
  "ATC 3 (Attempted to contact)",
  "ATC 4 (Attempted to contact)",
  "ATC 5 (Attempted to contact)",
  "ATC 6 (Attempted to contact)",
  "Qualified",
  "Requirement Gathering",
  "Profile(s) shared",
  "Interview Scheduled",
  "Closed by Client",
  "Finalized",
  "Candidate Onboarded",
];

const TEAM_STATUS = [
  "Pending",
  "Closed by Client",
  "Finalized",
  "Candidate Onboarded",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "60vh",
  overflowX: "scroll",
};

const TeamsReferrals = ({ searchParams }) => {
  const [referrals, setReferrals] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  // const [searchParams, setSearchParams] = useState({
  //   status: "Pending", // Pending, Not Qualified, Qualified, Requirement Gathering, Interview scheduled
  //   team_status: "Pending", // Closed by client, Finalized, Candidate onboarded
  // });
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: 1,
    data_per_page: 10,
    filter_by_status: null,
  });
  const [totalData, setTotalData] = useState({
    total_count: 0,
    total_page: 1,
  });
  const [conversationModal, setConversationModal] = useState(false);
  const [conversation, setConversation] = useState({});
  const [logsModal, setLogsModal] = useState(false);
  const [teamRefLog, setTeamRefLog] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const [commentTicket, setCommentTicket] = useState({
    comment: "",
    status: "",
    id: "",
    showComment: false,
    showTicket: false,
    amount: "0",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchReferrals = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("loggedUserToken"); // Get the token from local storage
      const { page_no, data_per_page, filter_by_status } = pagination;
      const params = {
        data_per_page,
        page_no,
        filter_by_status,
        ...searchParams,
      };
      setAuthToken(token); // Set the token in the request headers
      const response = await api.post("/referrals/teams-referrals", params);
      const { teams_data, totalCount } = response.data;
      const total_page = Math.ceil(parseInt(totalCount) / data_per_page);
      if (total_page === 1 && pagination.page_no === 1) {
        setDisablePrev(true);
        setDisableNext(true);
      }
      if (total_page > 1 && pagination.page_no === 1) {
        setDisableNext(false);
        setDisablePrev(true);
      }
      setReferrals(teams_data);
      setTotalData({
        total_data: parseInt(totalCount),
        total_page,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        dispatch(setUser(null));
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
    }
    // dispatch(isLoader(false))
    setIsLoading(false);
  };

  const checkValidPath = (path) => {
    if (path.includes("var") && path.includes("www")) {
      return true;
    } else {
      return false;
    }
  };

  const openConversationModal = async (referral) => {
    setConversationModal(true);
    setConversation({
      conversation_engage: referral?.conversation_engage,
      conversation_time: referral?.conversation_time,
      img: false,
      conversation: referral?.conversation,
      channel: referral?.conversation_channel,
    });
    if (
      referral?.conversation_img &&
      !checkValidPath(referral?.conversation_img)
    ) {
      setConversation((prevValue) => ({
        ...prevValue,
        img: referral?.conversation_img,
      }));
    } else if (
      referral?.conversation_img &&
      checkValidPath(referral?.conversation_img)
    ) {
      const imagePath = referral?.conversation_img;
      setIsImageLoading(true);
      try {
        const imageResponse = await api.post(`/referrals/conversation-image`,  { path: imagePath } ,{
          responseType: "blob", // Specify the response type as blob for binary data
        });

        // You can handle the image data here as needed
        // For example, you can convert it to a blob and create an object URL
        const imageBlob = imageResponse.data;
        const imageUrl = URL.createObjectURL(imageBlob);
        if (imageUrl) {
          setConversation((prevValue) => ({
            ...prevValue,
            img: imageUrl,
          }));
        }
        // Use the imageUrl in your component
        console.log("Image URL:", imageUrl);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          notifyError(error.response.data.message);
          dispatch(setUser(null));
          navigate("/login");
        } else if (error.response) {
          notifyError(error.response.data.message);
        } else {
          notifyError("Something went wrong");
        }
      } finally {
        setIsImageLoading(false);
      }
    }
  };

  const fetchTeamsReferralsLogs = async (id, deleted) => {
    setLogsModal(true);
    setIsLoading(true);
    setIsDelete(deleted);

    try {
      const response = await api.post("/referrals/teams-referrals-logs", {
        id: id,
      });
      const { status, team_status } = response?.data?.data;
      setTeamRefLog(response?.data?.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        dispatch(setUser(null));
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleRowClick = (referralId) => {
    setSelectedRow(referralId);
  };

  useEffect(() => {
    fetchReferrals();
  }, [pagination, dispatch, searchParams]);

  const handleSubmitStatus = async () => {
    Swal.fire({
      text:
        "Are you sure? you want to update the status" +
        `${
          commentTicket.status?.includes("ATC")
            ? ". This action will send an email."
            : ""
        }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (res) => {
      if (res?.isDismissed) {
        setCommentTicket({
          showComment: false,
          showTicket: false,
        });
      }

      if (res?.isConfirmed) {
        setIsLoading(true);
        const body = {
          status: commentTicket.status,
          comment: commentTicket.comment,
          amount: commentTicket.amount,
        };
        try {
          const response = await api.put(
            `/referrals/team-referrals/${commentTicket.id}`,
            body
          );
          await fetchReferrals();
          // window.location.reload();
          // notifySuccess(response.data.message);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            notifyError(error.response.data.message);
            dispatch(setUser(null));
            navigate("/login");
          } else if (error.response) {
            notifyError(error.response.data.message);
          } else {
            notifyError("Something went wrong");
          }
        } finally {
          setIsLoading(false);
          setCommentTicket({
            showComment: false,
            showTicket: false,
          });
        }
      } else {
      }
    });
  };

  const handleStatusUpdate = async () => {
    if (commentTicket.status === "Candidate Onboarded") {
      if (!commentTicket?.amount) {
        notifyError("Please enter amount");
        return;
      }
    }
    handleSubmitStatus();
  };

  const handleStatusChange = (value, id, updatedAt) => {
    if (value == "Candidate Onboarded") {
      return setCommentTicket({
        status: value,
        id: id,
        showTicket: true,
        showComment: false,
      });
    }
    setCommentTicket({
      status: value,
      id: id,
      showTicket: false,
      showComment: true,
    });
  };

  const handleNextPage = async () => {
    const currentPage = pagination.page_no;
    const total_page = totalData.total_page;

    setDisablePrev(false);
    setPagination((prevState) => ({
      ...prevState,
      page_no: currentPage + 1,
    }));

    if (currentPage === total_page - 1) {
      setDisableNext(true);
    }
  };

  const handlePrevPage = async () => {
    const currentPage = pagination.page_no;
    setDisableNext(false);
    setPagination((prevState) => ({
      ...prevState,
      page_no: currentPage - 1,
    }));

    if (currentPage - 1 === 1) {
      setDisablePrev(true);
    }
  };

  const downloadFile = async (filename) => {
    const fileExtension = filename.split(".").pop().toLowerCase();
    try {
      const headers = {
        Accept:
          fileExtension === "pdf"
            ? "application/pdf"
            : "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      };

      const response = await api.post(
        "/referrals/download-file",
        { filename },
        {
          responseType: "blob", // Specify the response type as a binary blob
          headers,
        }
      );

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      const filePath = filename;
      const parts = filePath.split("\\");
      const fileNameWithRelativePath = parts[parts.length - 1];

      const fileNameParts = fileNameWithRelativePath.split("/");
      const fileName = fileNameParts[fileNameParts.length - 1];

      a.download = fileName; // Set the desired file name
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "IST",
    });
  };

  const getComment = (arr, status) => {
    let comment = arr?.reverse()?.find((itm) => itm?.status == status);
    return comment?.comment?.length > 25 ? (
      <>
        {comment?.comment.slice(0, 25)} ...{" "}
        <p
          style={{
            color: "#126AFF",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            Swal.fire({
              title: "Comment",
              text: comment?.comment,
              confirmButtonColor: "#0d6efd",
              // icon: "info",
            });
          }}
        >
          read more.
        </p>
      </>
    ) : (
      comment?.comment
    );
  };

  const useStyles = makeStyles((theme) => ({
    active: {
      "& .MuiStepIcon-active": {
        color: "blue",
      },
    },

    inactive: {
      "& .MuiStepIcon-root.MuiStepIcon-completed": {
        color: "lightgray", // Change the tick mark color for inactive steps
      },
    },
  }));

  // Inside your component
  const classes = useStyles();

  return (
    <div>
      {!isLoading && (
        <div>
          {referrals?.length == 0 ? (
            <Notfound />
          ) : (
            <>
              <div className="table-container">
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Referred By</th>
                      <th>Referral Email</th>
                      <th>Referral Name</th>
                      <th>Phone</th>
                      <th>Referral ID</th>

                      <th>Company</th>
                      <th>Job Opening Source</th>
                      <th>Status</th>
                      <th>Ticket Size($)/Comments</th>
                      <th>Commision($)</th>
                      <th>Referral Date & Time</th>
                      <th>Contract / Fulltime</th>
                      <th>Country</th>
                      <th>Position</th>
                      <th>View Status Logs</th>
                      <th>Conversation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referrals.map((referral, i) => (
                      <tr
                        // style={{background:referral.deleted == '1' && '#DC8686'}}
                        key={referral.id}
                        className={
                          selectedRow === referral.id ? "active-row" : ""
                        }
                        onClick={() => handleRowClick(referral.id)}
                      >
                        <td>{referral.id}</td>
                        <td>{referral.referred_by}</td>
                        {/* <td>
                        {Number(pagination.page_no) == 1
                          ? i + 1
                          : Number(`${pagination.page_no - 1}` + "0") + (i + 1)}
                      </td> */}
                        <td>
                          {referral.email}
                          {/* &nbsp;&nbsp;
                          {referral?.deleted == "1" && (
                            <span className="red-delete-tag">
                              Deleted by user
                            </span>
                          )} */}
                        </td>
                        <td>{referral.full_name}</td>
                        <td>{referral.phone_number}</td>
                        <td>
                          {/* <Link
                        to={`/profile/${referral.referral_id}`}
                        style={{ textDecoration: "none", color: "red" }}
                      > */}
                          {referral.referral_id}
                          {/* </Link> */}
                        </td>
                        <td>{referral.company}</td>
                        <td>{referral.job_opening}</td>
                        <td>
                          <Select
                            referral={referral}
                            handleStatusChange={handleStatusChange}
                            setCommentTicket={setCommentTicket}
                          />
                        </td>
                        <td>
                          {!commentTicket.showComment &&
                          !commentTicket?.showTicket &&
                          referral?.status == "Candidate Onboarded"
                            ? referral?.ticket_size
                            : getComment(
                                referral?.team_augmentation_logs,
                                referral.status
                              )}
                          <>
                            {commentTicket?.showComment &&
                              commentTicket?.id == referral?.id && (
                                <div className="d-flex flex-column gap-2">
                                  <textarea
                                    rows={3}
                                    maxLength={100}
                                    placeholder="Comment...."
                                    value={commentTicket.comment}
                                    onChange={(e) =>
                                      setCommentTicket({
                                        ...commentTicket,
                                        comment: e.target.value,
                                      })
                                    }
                                  />
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleStatusUpdate()}
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}
                            {commentTicket?.showTicket &&
                              commentTicket?.id == referral?.id && (
                                <div className="d-flex flex-column gap-2">
                                  <input
                                    placeholder="Amount"
                                    defaultValue={referral?.ticket_size}
                                    type="number"
                                    pattern="[0-9]*"
                                    min="0"
                                    value={commentTicket?.amount}
                                    onKeyDown={(e) => {
                                      if (e.key === "+") {
                                        e.preventDefault();
                                      }
                                      if (e.key === "-") {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      if (
                                        e.target.value !== "-" &&
                                        e.target.value !== "+"
                                      ) {
                                        setCommentTicket({
                                          ...commentTicket,
                                          amount: e.target.value,
                                        });
                                      }
                                    }}
                                  />
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleStatusUpdate()}
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}
                          </>
                        </td>
                        <td>{referral?.commission ?? "-"}</td>
                        <td>
                          {moment(referral.createdAt).local().format("LL LT")}
                        </td>
                        <td>{referral?.role}</td>
                        <td>{referral?.country}</td>
                        <td>{referral?.type_of_position}</td>
                        <td>
                          {" "}
                          <FontAwesomeIcon
                            onClick={() =>
                              fetchTeamsReferralsLogs(
                                referral?.id,
                                referral?.deleted == 1 ? true : false
                              )
                            }
                            style={{
                              fontSize: "21px",
                              margin: 0,
                              cursor: "pointer",
                              color: "#126AFF",
                            }}
                            icon={faEye}
                          />
                        </td>
                        <td>
                          {" "}
                          <FontAwesomeIcon
                            onClick={() => openConversationModal(referral)}
                            style={{
                              fontSize: "21px",
                              margin: 0,
                              cursor: "pointer",
                              color: "#126AFF",
                            }}
                            icon={faEye}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div
                className="pagination-handler d-flex justify-content-end align-items-center"
                style={{ position: "sticky !important", top: 0, right: 10 }}
              >
                <button
                  className="btn btn-outline-primary"
                  onClick={handlePrevPage}
                  disabled={disablePrev}
                >
                  {"<"}
                </button>
                <span style={{ padding: "0px 10px" }}>
                  {pagination.page_no}
                </span>
                <button
                  className="btn btn-outline-primary"
                  onClick={handleNextPage}
                  disabled={disableNext}
                >
                  {">"}
                </button>
              </div>
            </>
          )}
        </div>
      )}
      {isLoading && (
        <div className="loading-container">
          <div className="loading"></div>
          <p>Loading...</p>
        </div>
      )}

      <Modal
        open={conversationModal}
        onClose={() => {
          setConversationModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            borderRadius: "7px",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            padding: "2rem 2rem",
          }}
          sx={style}
        >
          <div>
            <label>
              Did you engage in a conversation with them prior to sharing?{" "}
            </label>
            <p>{conversation?.conversation_engage}</p>
          </div>
          <div>
            <label>Date and Time </label>
            <p>
              {conversation?.conversation_time
                ? moment(conversation?.conversation_time)
                    .local()
                    .format("LL LT")
                : "-"}
            </p>
          </div>
          <div>
            <label>Channel </label>
            <p>{conversation?.channel ?? "-"}</p>
          </div>
          <div>
            <label>Conversation </label>
            <p>{conversation?.conversation ?? "-"}</p>
          </div>
          {isImageLoading ? (
            <div>
              <label>Conversation Screenshot </label>
              <br />
              Loading...
            </div>
          ) : (
            <div>
              <label>Conversation Screenshot </label>
              <br />
              {conversation?.img ? (
                <img
                  width={300}
                  alt="conversation_img"
                  src={conversation?.img}
                />
              ) : (
                "No Image Found!"
              )}
            </div>
          )}
        </Box>
      </Modal>

      <Modal
        open={logsModal}
        onClose={() => {
          setLogsModal(false);
          setTeamRefLog(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            borderRadius: "7px",
            display: "flex",
            gap: "2rem",
            padding: "2rem 2rem",
          }}
          sx={style}
        >
          <div>
            <Typography
              style={{ marginBottom: "1rem" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Resources
            </Typography>
            <div style={{ marginTop: "45px" }}></div>
            {teamRefLog?.resources?.map((data, index) => (
              <div className="pt-2 pb-2" key={index}>
                <p>
                  Requirement {teamRefLog.resources.length > 1 ? index + 1 : ""}
                </p>
                <div>
                  <label for="label1">Team Size:</label>
                  <span id="label1" className="ms-2">
                    {data.team_size}
                  </span>
                </div>
                <div>
                  <label for="label2">Technology:</label>
                  <span id="label2" className="ms-2">
                    {data.technology}
                  </span>
                </div>
                {data.filepath && (
                  <div>
                    <a
                      href="#"
                      id={index}
                      onClick={(e) => downloadFile(data.filepath)}
                    >
                      Download JD
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div style={{ width: "70%" }}>
            <Typography
              style={{ marginBottom: "2rem", marginLeft: "2rem" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Status
            </Typography>
            {isDelete ? (
              <h3
                style={{ marginLeft: "2rem", marginTop: "3rem", color: "red" }}
              >
                Deleted by user
              </h3>
            ) : (
              <Stepper
                activeStep={teamRefLog?.status?.length ?? 1}
                orientation="vertical"
              >
                {teamRefLog?.status?.map((item, index) => (
                  <Step key={item?.id} active={item.active}>
                    <StepLabel
                      classes={{
                        root: item.active ? classes.active : classes.inactive,
                      }}
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          color: item.active ? "black" : "lightgray",
                        }}
                      >
                        {" "}
                        {item?.status}
                      </span>
                    </StepLabel>
                    <StepContent>
                      {item?.comment && (
                        <Typography
                          style={{
                            fontSize: "14px",
                            whiteSpace: "pre-wrap",
                            overflow: "visible",
                          }}
                        >
                          <span
                            style={{ fontWeight: "700", overflow: "visible" }}
                          >
                            Comment:{" "}
                          </span>
                          {item?.comment}
                        </Typography>
                      )}
                      <Typography style={{ fontSize: "14px" }}>
                        <span style={{ fontWeight: "700" }}>Updated On:</span>{" "}
                        {moment(item?.createdAt).local().format("LL LT")}
                      </Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            )}

            {!isDelete && isLoading && (
              <h5 style={{ marginLeft: "2rem" }}>Loading....</h5>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default TeamsReferrals;
