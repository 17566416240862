import React, { useState, useEffect } from "react";
import styles from "./LoginForm.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { setAuthToken, api } from "../../../Api";
import { useSelector, useDispatch } from "react-redux";
import { validateEmail } from "../../util/Helper";
import store from "../../../Redux/store";
import {
  isLoader,
  setUser,
  modalState,
} from "../../../Redux/actions/userActions";
import { GoogleLogin } from "@react-oauth/google";
import { notifyError, notifySuccess } from "../Toaster/Toaster";
import { Box, Modal, Typography } from "@material-ui/core";

import { validate } from "../Validation";

// Import the necessary components from Material-UI
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

const LabelCustom = ({ isReq, text }) => {
  return (
    <label
      id="technology-label"
      htmlFor="technology-input"
      className="hs-form__field__label"
      data-required="true"
      style={{ fontSize: "14px" }}
    >
      <span style={{ marginLeft: "7px", color: !isReq && "gray" }}>{text}</span>
      {isReq && <span className="hs-form__field__label__required">*</span>}
    </label>
  );
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const LoginForm = () => {
  const navigate = useNavigate();
  const loggedUser = useSelector((state) => state.user.loggedUser);
  const modal = useSelector((state) => state.user.modal);

  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const [verified, setVerified] = useState(true);
  const [send, setSend] = useState(true);
  const [verificationToken, setVerificationToken] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(null);
  const [verifyingCredentials, setVerifyingCredentials] = useState(false);
  const [forgetPasswordMode, setForgetPasswordMode] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [formValues, setFormValues] = useState({
    email: "",
    token: "",
    password: "",
  });

  const [userPending, setUserPending] = useState({ modal: false });

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate({ email, password })) {
      return;
    }
    if (!validateEmail(email)) {
      notifyError("Enter a valid Email Address");
      return;
    }
    try {
      // setVerifyingCredentials(true);
      dispatch(isLoader(true));
      const response = await api.post("/auth/login", {
        email,
        password,
      });
      if (response.status === 200) {
        const user = response.data.user;
        const userObject = {
          userName: user?.userName,
          userId: user?.id,
          fullName: user?.fullName,
          email: user?.email,
          password: user?.password,
          createdAt: user?.createdAt,
          updatedAt: user?.updatedAt,
          userType: user?.userType,
          referralId: user?.referral_id,
        };

        if (userObject?.userType == "Pending") {
          Swal.fire({
            title: "Profile Under Review",
            text: "While we work our magic, go ahead and dive into the exciting features of our website. And hey, stay tuned for updates in your inbox!",
            confirmButtonColor: "#0d6efd",
            // icon: "info",
          });
          // notifyError(
          //   ""
          // );
        }
        if (userObject?.userType == "Blocked") {
          Swal.fire({
            title: "Profile Blocked",
            text: "Your Profile is blocked, you cannot login.",
            confirmButtonColor: "#0d6efd",
            // icon: "info",
          });
          // notifyError("");
        }

        // if (userObject?.userType == "Pending") {
        //   dispatch(modalState(true));
        //   // notifyError(
        //   //   "Your Profile is under review, please wait while the admin reviews your profile."
        //   // );
        // }
        // if (userObject?.userType == "Blocked") {
        //   dispatch(modalState(true));
        //   notifyError("Your Profile is blocked, you cannot login.");
        // }
        if (userObject?.userType == "User" || userObject?.userType == "Admin") {
          setLoginSuccess("success");
          setAuthToken(response.data.token);
          dispatch(setUser(userObject));
          localStorage.setItem("referral_id", user?.referral_id);
          notifySuccess("Logged in Successfully!");
          navigate("/home");
          dispatch(isLoader(false));
        }
      } else {
        notifyError("Error logging in. Please try again.");
      }
      dispatch(isLoader(false));
    } catch (error) {
      if (error.response && error.response.status === 400) {
        notifyError("Incorrect password. Please try again.");
      } else if (error.response && error.response.status === 404) {
        notifyError("User does not exist. Please try again.");
      } else if (error.response && error.response.status === 409) {
        notifyError(error?.response?.data?.message);
      } else if (error.response && error.response.status === 403) {
        navigate('/register?verify=1');
        localStorage.setItem('tempmail', error.response.data.user.email)
        notifyError("Email is not verified. Please Verify your Email");
        setEmail(error.response.data.user.email);
        setVerified(false);
      } else {
        console.error("There was a problem logging in:", error);
        notifyError("Error logging in. Please try again.");
      }
    } finally {
      dispatch(isLoader(false));
    }
  };

  const onSuccess = async (res) => {
    const userObject = jwtDecode(res.credential);
    const { email, given_name, family_name } = userObject;
    const fullName = given_name + " " + family_name;

    const user_email = email;

    const data = {
      email: user_email,
      fullName,
    };

    try {
      dispatch(isLoader(true));
      const response = await api.post("/auth/google-login", data);
      const user = response.data.user;
      const userObject = {
        userName: user?.userName,
        userId: user?.id,
        fullName: user?.fullName,
        email: user?.email,
        // password: user?.password,
        createdAt: user?.createdAt,
        updatedAt: user?.updatedAt,
        userType: user?.userType,
        referralId: user?.referral_id,
      };

      if (userObject?.userType == "Pending") {
        Swal.fire({
          title: "Profile Under Review",
          text: "Your Profile is under review, please wait while the admin reviews your profile.",
          confirmButtonColor: "#0d6efd",
          // icon: "info",
        });
        // notifyError(
        //   ""
        // );
      }
      if (userObject?.userType == "Blocked") {
        Swal.fire({
          title: "Profile Blocked",
          text: "Your Profile is blocked, you cannot login.",
          confirmButtonColor: "#0d6efd",
          // icon: "info",
        });
        // notifyError("");
      }
      if (userObject?.userType == "User" || userObject?.userType == "Admin") {
        setLoginSuccess("success");
        setAuthToken(response.data.token);
        localStorage.setItem("referral_id", user?.referral_id);
        store.dispatch(setUser(userObject));
        notifySuccess("Logged in Successfully!");
        navigate("/home");
      }
      dispatch(isLoader(false));
    } catch (err) {
      if (err.response) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Something went wrong!");
      }
      dispatch(isLoader(false));
    }
  };
  const onFailure = (err) => {
    console.log("failed", err);
  };

  const handleVerifyToken = async (event) => {
    event.preventDefault();

    try {
      setVerifyingCredentials(true);
      const response = await api.get("/auth/verify", {
        params: {
          email: email,
          token: verificationToken,
        },
      });

      if (response.status === 200) {
        setVerified(true);
        notifySuccess("Token verified!!! Please login again");
      } else {
        notifyError("Invalid token. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying token:", error);
      notifyError("Invalid token. Please try again.");
    } finally {
      setVerifyingCredentials(false);
    }
  };
  const handleOtpVerify = async (event) => {
    event.preventDefault();

    try {
      setVerifyingCredentials(true);
      const response = await api.get("/auth/verify", {
        params: {
          email: resetEmail,
          token: verificationToken,
          is_password_reset: true,
        },
      });

      if (response.status === 200) {
        setForgetPassword(true);

        setFormValues({ ...formValues, token: verificationToken });
        setLoginSuccess("OTP verified!!!");
      } else {
        notifyError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      notifyError("Invalid OTP. Please try again.");
    } finally {
      setVerifyingCredentials(false);
    }
  };

  useEffect(() => {
    if (loginSuccess) {
      const timer = setTimeout(() => {
        setLoginSuccess(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [loginSuccess]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error]);
  const handleForgetPassword = (event) => {
    event.preventDefault();
    setForgetPasswordMode(true);
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    if (!validateEmail(resetEmail)) {
      notifyError("Enter a valid Email Address");
      return;
    }
    try {
      setVerifyingCredentials(true);
      // Implement the logic to send the password reset email
      // using the `resetEmail` value
      const response = await api.post("/auth/reset-password", {
        email: resetEmail,
      });
      if (response.status === 200) {
        setSend(false);
        setFormValues({ ...formValues, email: resetEmail });
        notifySuccess("Reset OTP sent successfully!!!");
      }

      // Show a success message or redirect the user to a success page
    } catch (error) {
      if (error.response.status === 404) {
        notifyError("Email Not registered");
      } else {
        notifyError("Error resetting password. Please try again.");
      }
    } finally {
      setVerifyingCredentials(false);
    }
  };

  const handlePasswordChange = (event) => {
    setFormValues({ ...formValues, password: event.target.value });
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  const handleReset = async (event) => {
    event.preventDefault();
    if (formValues.password !== confirmPassword) {
      notifyError("Passwords do not match");
      return;
    }
    try {
      setVerifyingCredentials(true);
      // Implement the logic to send the password reset email
      // using the `resetEmail` value
      const response = await api.post("/auth/reset", formValues);

      if (response.status === 200) {
        notifySuccess("Password Reset Sucessfull!!!");
        setForgetPasswordMode(false);
        setForgetPassword(false);
      }

      // Show a success message or redirect the user to a success page
    } catch (error) {
      if (error.response.status === 404) {
        notifyError("Email Not registered");
      } else {
        notifyError("Error resetting password. Please try again.");
      }
    } finally {
      setVerifyingCredentials(false);
      setSend(true);
    }
  };

  if (forgetPassword) {
    return (
      <div className={styles.container}>
        <form className={styles.form} onSubmit={handleReset}>
          <h2 className={styles.heading}>Reset Password</h2>
          <div className={styles.formGroup}>
            <input
              type="password"
              id="password"
              name="password"
              value={formValues.password}
              onChange={handlePasswordChange}
              placeholder="*Password"
              required
            />
            <br />
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeholder="*Confirm Password"
              required
            />
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={`${styles.button} ${styles.roundedBtn}`}
              type="submit"
              style={{ marginTop: "0" }}
              disabled={verifyingCredentials}
            >
              {verifyingCredentials
                ? "Changing password. Please wait..."
                : "Change Password"}
            </button>
          </div>
        </form>
        {error && (
          <Snackbar open={true} autoHideDuration={3000}>
            <Alert severity="error">{error}</Alert>
          </Snackbar>
        )}
        {loginSuccess && (
          <Snackbar open={true} autoHideDuration={3000}>
            <Alert severity="success">{loginSuccess}</Alert>
          </Snackbar>
        )}
      </div>
    );
  }

  if (forgetPasswordMode) {
    return (
      <div className={styles.container}>
        {send ? (
          <>
            <form
              className={styles.form}
              onSubmit={handleResetPassword}
              style={{ paddingBottom: "2.15rem" }}
            >
              <h2 className={styles.heading} style={{ marginBottom: "0.7rem" }}>
                Reset Password
              </h2>
              <label
                className="text-center"
                style={{
                  marginBottom: "1rem",
                  fontSize: "13.5px",
                  padding: "0 7px",
                }}
              >
                Fill in your email and we’ll send you a code to reset your
                password.
              </label>

              <div className={styles.formGroup}>
                <LabelCustom isReq={true} text={"Email"} />
                <input
                  type="email"
                  id="resetEmail"
                  required={true}
                  placeholder="example@gmail.com"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                />
              </div>
              <div className={styles.buttonContainer}>
                <button
                  className={`${styles.button} ${styles.roundedBtn}`}
                  style={{ marginTop: "0" }}
                  type="submit"
                  disabled={verifyingCredentials}
                >
                  {verifyingCredentials
                    ? "Sending OTP. Please wait..."
                    : "Send OTP"}
                </button>
              </div>
            </form>
            {error && (
              <Snackbar open={true} autoHideDuration={3000}>
                <Alert severity="error">{error}</Alert>
              </Snackbar>
            )}
            {loginSuccess && (
              <Snackbar open={true} autoHideDuration={3000}>
                <Alert severity="success">{loginSuccess}</Alert>
              </Snackbar>
            )}
          </>
        ) : (
          <>
            <form
              className={styles.form}
              onSubmit={handleOtpVerify}
              style={{ paddingBottom: "2.15rem" }}
            >
              <h2 className={styles.heading} style={{ marginBottom: "0.7rem" }}>
                OTP Verification
              </h2>
              <div className={styles.formGroup}>
                <label
                  htmlFor="verificationToken"
                  style={{
                    color: "#007bff",
                    fontSize: "14px",
                    margin: "0 0 1.1rem 0",
                    textAlign: "center",
                  }}
                >
                  <span style={{ color: "#000", fontWeight: "400" }}>
                    Enter OTP received on
                  </span>{" "}
                  {resetEmail}
                </label>
                <input
                  type="text"
                  maxLength={100}
                  id="verificationToken"
                  required={true}
                  placeholder="******"
                  value={verificationToken}
                  onChange={(e) => setVerificationToken(e.target.value)}
                />
              </div>
              <div className={styles.buttonContainer}>
                <button
                  className={`${styles.button} ${styles.roundedBtn}`}
                  type="submit"
                  style={{ marginTop: "0" }}
                  disabled={verifyingCredentials}
                >
                  {verifyingCredentials
                    ? "Verifying OTP. Please wait..."
                    : "Verify OTP"}
                </button>
              </div>
            </form>
            {error && (
              <Snackbar open={true} autoHideDuration={3000}>
                <Alert severity="error">{error}</Alert>
              </Snackbar>
            )}
            {loginSuccess && (
              <Snackbar open={true} autoHideDuration={3000}>
                <Alert severity="success">{loginSuccess}</Alert>
              </Snackbar>
            )}
          </>
        )}
        <div className={styles.signuplink}>
          <a
            href="#"
            onClick={() => {
              setForgetPasswordMode(false);
              setSend(true);
            }}
          >
            Back to login.
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {/* <Modal
        open={modal}
        onClose={() => {
          dispatch(modalState(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{ borderRadius: "7px" }} sx={style}>
          <Typography
            style={{ marginBottom: "2rem" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Your Profile is under review, please wait while the admin reviews
            your profile.
          </Typography>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="btn btn-outline-primary"
              onClick={() => {
                dispatch(modalState(false));
              }}
            >
              OK
            </button>
          </div>
        </Box>
      </Modal> */}

      {verified ? (
        <>
          <form className={styles.form} onSubmit={handleSubmit}>
            <h2 className={styles.heading} style={{ marginBottom: "0rem" }}>
              Affiliate Login
            </h2>
            <div className="d-flex justify-content-center mb-3">
              {/* <GoogleLogin
                onSuccess={onSuccess}
                onError={onFailure}
                onFailure={onFailure}
              /> */}
            </div>
            {/* <GoogleLogin
              className="logingoogle"
              clientId={
                "848341583801-8u35u83j98ce775f3ep8gtichi842a7u.apps.googleusercontent.com"
              }
              render={(renderProps) => (
                <div
                  style={{
                    border: "1px solid #43BE9ADB",
                    borderRadius: "16px",
                    width: "40%",
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  className="googlecontinue logingoogle"
                  onClick={renderProps.onClick}
                >
                  <img
                    src="img/google.png"
                    alt="google"
                    height="20"
                    width="20"
                  />
                  <p
                    style={{
                      fontWeight: "600",
                      color: "#43BE9ADB",
                      top: "9px",
                      position: "relative",
                      left: "13px",
                    }}
                  >
                    Sign in with Google
                  </p>
                </div>
              )}
              buttonText="Sign in with Google"
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={"single_host_origin"}
              isSignedIn={false}
              shape="pill"
            /> */}
            <div className={styles.formGroup}>
              <LabelCustom isReq={true} text="Email" />
              <input
                type="email"
                id="email"
                value={email}
                placeholder="example@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.formGroup}>
              <LabelCustom isReq={true} text="Password" />
              <input
                type="password"
                id="password"
                value={password}
                placeholder="*********"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div
              className={`${styles.formGroup} ${styles.rememberForgotContainer}`}
            >
              <div className="d-flex justify-content-center align-items-centers">
                <input
                  type="checkbox"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label
                  htmlFor="rememberMe"
                  style={{ fontSize: "14px", margin: 0 }}
                >
                  Remember me
                </label>
              </div>
              <a href="#" onClick={handleForgetPassword}>
                Forgot password?
              </a>
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={`${styles.button} ${styles.roundedBtn}`}
                style={{ marginTop: "0" }}
                type="submit"
                disabled={verifyingCredentials}
              >
                {verifyingCredentials
                  ? "Verifying credentials. Please wait..."
                  : "Login"}
              </button>
            </div>
            {error && (
              <Snackbar open={true} autoHideDuration={3000}>
                <Alert severity="error">{error}</Alert>
              </Snackbar>
            )}
            {loginSuccess && (
              <Snackbar open={true} autoHideDuration={3000}>
                <Alert severity="success">{loginSuccess}</Alert>
              </Snackbar>
            )}
          </form>
          <div className={styles.signuplink}>
            Don't have an account? <Link to="/register">Sign Up</Link>
          </div>
        </>
      ) : (
        <>
          <form className={styles.form} onSubmit={handleVerifyToken}>
            <h2 className={styles.heading}>Email Verification</h2>
            <div className={styles.formGroup}>
              <label htmlFor="verificationToken">
                Enter Token received on {email}:
              </label>
              <input
                type="text"
                maxLength={100}
                id="verificationToken"
                value={verificationToken}
                onChange={(e) => setVerificationToken(e.target.value)}
              />
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={`${styles.button} ${styles.roundedBtn}`}
                type="submit"
                disabled={verifyingCredentials}
              >
                {verifyingCredentials
                  ? "Verifying Email. Please wait..."
                  : "Verify Email"}
              </button>
            </div>
          </form>
          {error && (
            <Snackbar open={true} autoHideDuration={3000}>
              <Alert severity="error">{error}</Alert>
            </Snackbar>
          )}
        </>
      )}
    </div>
  );
};

export default LoginForm;
