const initialState = {
  loggedUser: null,
  isLoading: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        loggedUser: action.payload,
      };
    case 'IS_LOADING':
      return {
        ...state,
        isLoading: action.payload
      }
      case 'MODAL_STATE':
        return {
          ...state,
          modal: action.payload
        }
    default:
      return state;
  }
};

export default userReducer;