import React, { useEffect, useState } from "react";
import "./UserPending.css";

const UserPending = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    const text =
      "Your Affiliate profile is still waiting for confirmation from ADMIN. We will get back to you soon.";
    let index = 0;

    const timer1 = setInterval(() => {
      if (index === text.length) {
        clearInterval(timer1);
      } else {
        setContent(text.substring(0, index + 1));
        index++;
      }
    }, 50);

    return () => {
      clearInterval(timer1);
    };
  }, []);

  return (
    <div className="user-profile1">
      <h1>Affiliate Dashboard</h1>
      <p>{content}</p>
    </div>
  );
};

export default UserPending;
