import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api } from "../../../Api";
import moment from "moment";
import { notifyError, notifySuccess } from "../../util/Toaster/Toaster";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setUser } from "../../../Redux/actions/userActions";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Button,
  Modal,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Swal from "sweetalert2";

const ReferralsGivenTeamTable = ({ referrals, style }) => {
  const getLeadStatusText = (leadStatus) => {
    switch (leadStatus) {
      case 0:
        return "Pending";
      case 1:
        return "Qualified";
      case 2:
        return "Converted";
      case 3:
        return "Rejected";
      default:
        return "";
    }
  };

  const getComment = (arr, status) => {
    let comment = arr?.reverse()?.find((itm) => itm?.status == status);
    return comment?.comment?.length > 25 ? (
      <>
        {comment?.comment.slice(0, 25)} ...{" "}
        <p
          style={{
            color: "#126AFF",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            Swal.fire({
              title: "Comment",
              text: comment?.comment,
              confirmButtonColor: "#0d6efd",
              // icon: "info",
            });
          }}
        >
          read more.
        </p>
      </>
    ) : (
      comment?.comment
    );
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [logsModal, setLogsModal] = useState(false);
  const [teamRefLog, setTeamRefLog] = useState(null);

  const fetchTeamsReferralsLogs = async (id) => {
    setLogsModal(true);
    setIsLoading(true);

    try {
      const response = await api.post("/referrals/teams-referrals-logs", {
        id: id,
      });
      const { status, team_status } = response?.data?.data;
      setTeamRefLog(response?.data?.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        dispatch(setUser(null));
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteReferralUser = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
    }).then(async (res) => {
      if (res?.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await api.post(
            "/referrals/user/team-referrals/delete",
            {
              id: id,
            }
          );
          notifySuccess(response.data.message);
          window.location.reload();
        } catch (error) {
          if (error.response && error.response.status === 401) {
            notifyError(error.response.data.message);
            dispatch(setUser(null));
            navigate("/login");
          } else if (error.response) {
            notifyError(error.response.data.message);
          } else {
            notifyError("Something went wrong");
          }
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "IST",
    });
  };

  const useStyles = makeStyles((theme) => ({
    active: {
      "& .MuiStepIcon-active": {
        color: "blue",
      },
    },

    inactive: {
      "& .MuiStepIcon-root.MuiStepIcon-completed": {
        color: "lightgray", // Change the tick mark color for inactive steps
      },
    },
  }));

  // Inside your component
  const classes = useStyles();

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: "60vh",
    overflowX: "scroll",
  };

  return (
    <table className="styled-table" style={{ ...style, boxShadow: "none" }}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Company</th>
          <th>Phone Number</th>
          {/* <th>Technology</th> */}
          <th>Lead Status</th>
          <th>Comment/Ticket Size($)</th>
          <th>Commission($)</th>
          <th>Date & Time</th>
          <th>View Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {referrals?.referrals?.map(
          (referral) =>
            referral?.deleted != "1" && (
              <tr key={referral.id}>
                <td>{referral.full_name} </td>
                <td>{referral.email}</td>
                <td>{referral.company}</td>
                <td>{referral.phone_number}</td>
                {/* <td>{referral.technology}</td> */}
                <td>{referral.status}</td>
                <td>
                  {referral.status == "Qualified"
                    ? referral?.ticket_size
                    : getComment(
                        referral?.team_augmentation_logs,
                        referral.status
                      )}
                </td>
                <td>{referral.commission}</td>
                <td>{moment(referral.createdAt).local().format("LL LT")}</td>
                <td style={{ textAlign: "center" }}>
                  {" "}
                  <FontAwesomeIcon
                    onClick={() => fetchTeamsReferralsLogs(referral?.id)}
                    style={{
                      fontSize: "21px",
                      margin: 0,
                      cursor: "pointer",
                      color: "#126AFF",
                    }}
                    icon={faEye}
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  <FontAwesomeIcon
                  data-toggle="tooltip" data-placement="left" title={ referral?.status !== "Pending" ? "Only Pending referrals can be deleted." : ""}
                    style={{
                      fontSize: "16px",
                      margin: 0,
                      color: referral?.status !== "Pending" ? "#8c8c8c" : "",
                    }}
                    className="custom-icon"
                    icon={faTrash}
                    onClick={(e) => {
                      if (referral?.status === "Pending") {
                        handleDeleteReferralUser(referral.id);
                      }
                    }}
                  />
                </td>
              </tr>
            )
        )}
      </tbody>
      <Modal
        open={logsModal}
        onClose={() => {
          setLogsModal(false);
          setTeamRefLog(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            borderRadius: "7px",
            display: "flex",
            gap: "2rem",
            padding: "2rem 2rem",
          }}
          sx={ModalStyle}
        >
          <div>
            <Typography
              style={{ marginBottom: "1rem" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Resources
            </Typography>
            <div style={{ marginTop: "45px" }}></div>
            {teamRefLog?.resources?.map((data, index) => (
              <div className="pt-2 pb-2">
                <p>
                  Requirement{" "}
                  {teamRefLog?.resources?.length > 1 ? index + 1 : ""}
                </p>
                <div>
                  <label for="label1">Team Size:</label>
                  <span id="label1" className="ms-2">
                    {data.team_size}
                  </span>
                </div>
                <div>
                  <label for="label2">Technology:</label>
                  <span id="label2" className="ms-2">
                    {data.technology}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div style={{ width: "70%" }}>
            <Typography
              style={{ marginBottom: "1rem", marginLeft: "2rem" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Status
            </Typography>
            <Stepper
              activeStep={teamRefLog?.status?.length ?? 1}
              orientation="vertical"
            >
              {teamRefLog?.status?.map((item, index) => (
                <Step key={item?.id} active={item.active}>
                  <StepLabel
                    classes={{
                      root: item.active ? classes.active : classes.inactive,
                    }}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        color: item.active ? "black" : "lightgray",
                      }}
                    >
                      {" "}
                      {item?.status}
                    </span>
                  </StepLabel>
                  <StepContent>
                    {item?.comment && (
                      <Typography
                        style={{
                          fontSize: "14px",
                          whiteSpace: "pre-wrap",
                          overflow: "visible",
                        }}
                      >
                        <span style={{ fontWeight: "700" }}>Comment: </span>{" "}
                        {item?.comment}
                      </Typography>
                    )}
                    <Typography style={{ fontSize: "14px" }}>
                      <span style={{ fontWeight: "700" }}>Updated On:</span>{" "}
                      {moment(item?.createdAt).local().format("LL LT")}
                    </Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {isLoading && <h5 style={{ marginLeft: "2rem" }}>Loading....</h5>}
          </div>
        </Box>
      </Modal>
    </table>
  );
};

export default ReferralsGivenTeamTable;
