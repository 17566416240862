import React, { useEffect, useState } from "react";
import styles from "./AffiliateWidget.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import ClipBoard from "../../util/ClipBoard/ClipBoard";

const AffiliateWidget = ({ userData }) => {
  const [uniqueId, setUniqueId] = useState("");

  useEffect(() => {
    setUniqueId(userData);
  }, [userData]);

  const handleSocialIconClick = (socialSite) => {
    switch (socialSite) {
      case "facebook":
        const facebookURL = encodeURIComponent(
          `https://app.prepai.io/register?ref=${uniqueId}`
        ); // Replace with your Facebook sharing URL
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${facebookURL}`
        );
        break;
      case "twitter":
        const twitterURL = encodeURIComponent(
          `https://app.prepai.io/register?ref=${uniqueId}`
        );
        window.open(`https://twitter.com/intent/tweet?url=${twitterURL}`);
        break;
      case "linkedin":
        const linkedinURL = encodeURIComponent(
          `https://app.prepai.io/register?ref=${uniqueId}`
        );
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${linkedinURL}`
        );
        break;
      case "whatsapp":
        const referralURL = encodeURIComponent(
          `https://app.prepai.io/register?ref=${uniqueId}`
        );
        window.open(`https://api.whatsapp.com/send?text=${referralURL}`);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <p>
          <img
            decoding="async"
            width="250"
            height="63"
            src={`${process.env.REACT_APP_PROJECT_URL}/img/prepai-logo.png`}
            alt="Black-Logo-Final"
            loading="lazy"
          />
        </p>
        <p style={{whiteSpace:"pre-wrap"}}>Most advanced AI-powered question paper generator.</p>
        <p>
          <a href={`https://app.prepai.io/register?ref=${uniqueId}`} target="_blank" className={styles.copyLink}>
            Open your affiliate link :
          </a>
        </p>
        <p>
          {/* {`https://app.prepai.io/register?ref=${uniqueId}`} */}
          <span>
            <ClipBoard
              link={`https://app.prepai.io/register?ref=${uniqueId}`}
            />
          </span>
        </p>
        <p className={styles.socialIcons}>
              <ul className={styles.listIcons}>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    onClick={() => handleSocialIconClick("facebook")}
                  >
                      <img
                      decoding="async"
                      width="35"
                      height="35"
                      src={`${process.env.REACT_APP_PROJECT_URL}/img/facebook-icon.png`}
                      alt="facebook-logo"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    onClick={() => handleSocialIconClick("twitter")}
                  >
                   <img
                      decoding="async"
                      width="35"
                      height="35"
                      src={`${process.env.REACT_APP_PROJECT_URL}/img/twitter-icon.png`}
                      alt="twitter-logo"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    onClick={() => handleSocialIconClick("linkedin")}
                  >
                 <img
                      decoding="async"
                      width="35"
                      height="35"
                      src={`${process.env.REACT_APP_PROJECT_URL}/img/linkedin-icon.png`}
                      alt="linkedin-logo"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    onClick={() => handleSocialIconClick("whatsapp")}
                  >
                    <img
                      decoding="async"
                      width="35"
                      height="35"
                      src={`${process.env.REACT_APP_PROJECT_URL}/img/whatsapp-icon.png`}
                      alt="whatsapp-logo"
                      loading="lazy"
                    />
                  </a>
                </li>
              </ul>
            </p>
      </div>
    </div>
  );
};

export default AffiliateWidget;
