import React from "react";
import styles from "./SectionPage.module.css";
import { RiCheckboxCircleFill } from "react-icons/ri"; // Importing the checkbox circle icon from react-icons library
import ReactPlayer from "react-player";

const SectionPage = () => {
  return (
    <div className={styles.container}>
      {/* <div className={styles.imageContainer}>
        <ReactPlayer
          url="videos/affiliate_program_team_augmentation_datatobiz.mp4"
          controls={true}
        />
      </div> */}
      <div className={styles.contentContainer}>
        <h1 className={styles.heading}>Who can become an affiliate partner?</h1>
        <ul className={styles.list}>
          <li data-aos="fade-left" data-aos-delay="300">
            <RiCheckboxCircleFill className={styles.icon} /> Anyone having a
            platform to promote products and services.
          </li>
          <li data-aos="fade-left" data-aos-delay="400">
            <RiCheckboxCircleFill className={styles.icon} /> Affiliates looking
            for attractive and uncapped commissions.
          </li>
          <li data-aos="fade-left" data-aos-delay="500">
            <RiCheckboxCircleFill className={styles.icon} /> Freelancers,
            bloggers, review sites, and content creators looking for extra
            income.
          </li>
          <li data-aos="fade-left" data-aos-delay="600">
            <RiCheckboxCircleFill className={styles.icon} /> Affiliates with
            minimum-level business traffic.
          </li>
          <li data-aos="fade-left" data-aos-delay="700">
            <RiCheckboxCircleFill className={styles.icon} /> Online/Offline
            marketing experts, associates, consultants, etc.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionPage;
