function validateEmail(email) {
  if (!email) {
    return false;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function validateFullName(fullName) {
  // Remove leading and trailing whitespaces
  fullName = fullName.trim();

  // Split the full name into words using whitespace as the delimiter
  const words = fullName.split(/\s+/);

  // Check if the full name has exactly three words
  return words.length <= 3;
}

export { validateEmail, validateFullName };
