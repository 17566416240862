import React, { useState } from "react";
import { Link } from "react-router-dom";
import VideoPopup from "../../common/videoPopup";

export const Noreferral = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          textAlign: "center",
          background: "rgb(235,243,255)",
          padding: "2rem",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            width: "780px",
            minWidth: "600px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "1.35rem",
          }}
        >
          <h1 style={{ fontSize: "34px", fontWeight: "700" }}>
            Grow with DataToBiz’s affiliate campaign
          </h1>
          <p style={{ whiteSpace: "pre-wrap", margin: 0 }}>
            DataToBiz’s affiliates earn healthy commissions by promoting the
            Team augmentation services to the companies and businesses that need
            it. This allows your referrals a hassle free onboarding of data
            engineering team with a predictable success rate.
          </p>
          <Link to="/referrals" className="link-user" style={{marginTop:'10px'}}>
            <button className="btn-user">Start earning now</button>
          </Link>
        </div>
      </div>
      <br />
      <img
        // className="cls-img"
        className="left-margin-ref"
        alt="img"
        style={{ width: "58%", cursor: "pointer" }}
        src="img/placeholder_image.png"
        onClick={openPopup}
      ></img>
      <div>
        {isPopupOpen && (
          <VideoPopup
            videoUrl="videos/affiliate_program_team_augmentation_datatobiz.mp4"
            isOpen={isPopupOpen}
            onRequestClose={closePopup}
          />
        )}
      </div>
    </>
  );
};
