import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import styles from "./ServiceCard.module.css";
import ClipBoard from "../../../util/ClipBoard/ClipBoard";

const services = [
  {
    id: 1,
    serviceName: "Data Analytics",
    serviceInfo:
      "Efficient data analytics mechanisms for future business decisions.",
    link: "data-analytics",
  },
  {
    id: 2,
    serviceName: "Data Warehousing",
    serviceInfo:
      "Most effective, reliable, and secured data warehousing solutions",
    link: "data-warehousing",
  },
  {
    id: 3,
    serviceName: "Big Data",
    serviceInfo:
      "Effective data management with high-impact big data services.",
    link: "big-data",
  },
  {
    id: 4,
    serviceName: "Artificial Intelligence",
    serviceInfo:
      "Revolutionize your business processes with AI and automation.",
    link: "Artificial-Intelligence",
  },
  {
    id: 5,
    serviceName: "Natural Language Processing",
    serviceInfo: "An one-stop NLP solution for your business.",
    link: "Natural-Language-Processing",
  },
  {
    id: 6,
    serviceName: "Vision Analytics",
    serviceInfo: "Market leading computer vision consulting services.",
    link: "Vision-Analytics",
  },
  {
    id: 7,
    serviceName: "AI Staff Augmentation",
    serviceInfo: "Hire your own AI-ML team for a business makeover.",
    link: "hire-ai-engineers",
  },
  {
    id: 8,
    serviceName: "Hire Tableau Engineers",
    serviceInfo: "Data Transformation with Tableau engineers from DataToBiz",
    link: "Hire-Tableau-Engineers",
  },
  {
    id: 9,
    serviceName: "Hire Azure Data Engineers",
    serviceInfo: "Simplify Azure With expert data engineering team.",
    link: "Hire-Azure-Data-Engineers",
  },
  {
    id: 10,
    serviceName: "Power BI",
    serviceInfo: "Offering 360-degree Power BI solutions for your business.",
    link: "power-bi-services-usa",
  },
  {
    id: 11,
    serviceName: "Business Intelligence",
    serviceInfo: "Enter the new era of business with top-notch BI services",
    link: "business-Intelligence",
  },
];

const Services = ({ userData }) => {
  const [uniqueId, setUniqueId] = useState("");

  useEffect(() => {
    setUniqueId(userData);
  }, []);

  const handleSocialIconClick = (link, socialSite) => {
    switch (socialSite) {
      case "facebook":
        const facebookURL = encodeURIComponent(
          `https://www.datatobiz.com/${link}/?ref=${uniqueId}`
        ); // Replace with your Facebook sharing URL
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${facebookURL}`
        );
        break;
      case "twitter":
        const twitterURL = encodeURIComponent(
          `https://www.datatobiz.com/${link}/?ref=${uniqueId}`
        );
        window.open(`https://twitter.com/intent/tweet?url=${twitterURL}`);
        break;
      case "linkedin":
        const linkedinURL = encodeURIComponent(
          `https://www.datatobiz.com/${link}/?ref=${uniqueId}`
        );
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${linkedinURL}`
        );
        break;
      case "whatsapp":
        const referralURL = encodeURIComponent(
          `https://www.datatobiz.com/${link}/?ref=${uniqueId}`
        );
        window.open(`https://api.whatsapp.com/send?text=${referralURL}`);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.servicesContainer}>
      {services.map((service) => (
        <div key={service.id} className={styles.card}>
          <div className={styles.cardContent}>
            <h3>{service.serviceName}</h3>
            <p>{service.serviceInfo}</p>
            <p>
              <a
                href={`https://www.datatobiz.com/${service.link}/?ref=${uniqueId}`}
                className={styles.copyLink}
                target="_blank"
              >
                Open your affiliate link:
              </a>
            </p>
            <p className={styles.ToCopyURL}>
              <span>
                <ClipBoard
                  link={`https://www.datatobiz.com/${service.link}/?ref=${uniqueId}`}
                ></ClipBoard>
              </span>
            </p>

            <p className={styles.socialIcons}>
              <ul className={styles.listIcons}>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    onClick={() =>
                      handleSocialIconClick(service.link, "facebook")
                    }
                  >
                    <img
                      decoding="async"
                      width="35"
                      height="35"
                      src={`${process.env.REACT_APP_PROJECT_URL}/img/facebook-icon.png`}
                      alt="facebook-logo"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    onClick={() =>
                      handleSocialIconClick(service.link, "twitter")
                    }
                  >
                    <img
                      decoding="async"
                      width="35"
                      height="35"
                      src={`${process.env.REACT_APP_PROJECT_URL}/img/twitter-icon.png`}
                      alt="twitter-logo"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    onClick={() =>
                      handleSocialIconClick(service.link, "linkedin")
                    }
                  >
                    <img
                      decoding="async"
                      width="35"
                      height="35"
                      src={`${process.env.REACT_APP_PROJECT_URL}/img/linkedin-icon.png`}
                      alt="linkedin-logo"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    onClick={() =>
                      handleSocialIconClick(service.link, "whatsapp")
                    }
                  >
                    <img
                      decoding="async"
                      width="35"
                      height="35"
                      src={`${process.env.REACT_APP_PROJECT_URL}/img/whatsapp-icon.png`}
                      alt="whatsapp-logo"
                      loading="lazy"
                    />
                  </a>
                </li>
              </ul>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Services;
