import React from "react";
import styles from "./TagLine.module.css";
import { Link } from "react-router-dom";

const TagLine = () => {
  return (
    <div className={styles.container} data-aos="zoom-in" data-aos-delay="300">
      <div className={styles.tagBox}>
        <div className={styles.tags}>
          <div className={styles.tagsHead}>
            <h1 className={styles.tag1} style={{ fontSize: "34px !important" }}>
              Turn Referrals into Revenue, Today!
            </h1>
            <span className={styles.subtag}>
              {" "}
              Maximize Income Opportunities with Our Data Engineering Team
              Augmentation Referral Program.
            </span>
            <span className={styles.subtag} style={{ fontSize: "16px !important" }}>
              Start earning up to 5% commission.
            </span>
          </div>
          <Link
            to="/register"
            style={{ paddingRight: "2.2vw", fontSize: "16px " }}
          >
            <button className={styles.buttonAtLayout}>Become a Partner</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TagLine;
