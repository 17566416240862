import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const FourOhFour = () => {
  const loggedUser = useSelector((state) => state.user.loggedUser);
  const isLoading = useSelector((state) => state.user.isLoading);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const lastPageURL = searchParams.get("from") || "/";

  useEffect(() => {
    if (loggedUser && loggedUser?.userType == "Admin") {
      navigate(lastPageURL);
      // console.log(lastPageURL)
    }
  }, []);

  if (!isLoading)
    return (
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ minHeight: "75vh" }}
      >
        <h1 style={{ fontSize: "4rem", fontWeight: "600", color: "#0d6efd" }}>
          404
        </h1>
        <h1 style={{ fontSize: "2rem", fontWeight: "600", color: "#0d6efd" }}>
          Page Not Found
        </h1>
      </div>
    );
};
export default FourOhFour;
