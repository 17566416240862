import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#111827',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        '&:focus': {
            opacity: 1,
        },
        '& > span': {
            flexDirection: 'row',
            justifyContent: 'start'
        }
    },

}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'start',
        backgroundColor: 'transparent',
        '& > span': {
            width: '100%',
            backgroundColor: '#126AFF',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


export {
    StyledTab,
    StyledTabs
}
