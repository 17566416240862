import { useState } from "react";
import styles from "./ClipBoard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const ClipBoard = (props) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(props.link);
    setIsCopied(true);
    alert("Link is copied to clipboard!");
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className={styles.clipboard}>
      <span>{props.link}</span>
      <button className="btn btn-outline-info" onClick={() => handleCopy()}>
        <FontAwesomeIcon icon={faCopy} size="lg" className={styles.copyIcon} />
      </button>
    </div>
  );
};

export default ClipBoard;
