import React from "react";
import ReactDOM from "react-dom";
// import "bootstrap/dist/css/bootstrap.css";
// import "./Partners.css"
import styles from "./Partners.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faHandHoldingDollar,
  faArrowTrendUp,
  faTrophy,
  faPeopleCarryBox,
  faLightbulb,
  faSignal,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

const Partners = () => {
  return (
    <div className={styles.works}>
      <div className={styles.worksContainer}>
        <h1
          className={styles.heading}
          data-aos="fade-right"
          data-aos-duration="500"
        >
          Why should you become a DataToBiz affiliate?
        </h1>
        <div
          className={styles.wrapper}
          data-aos="fade-right"
          data-aos-duration="500"
        >
          <div className={styles.card1}>
            <div>
              <FontAwesomeIcon
                icon={faGlobe}
                style={{ color: "#1266f8" }}
                size="3x"
              />
            </div>
            <div>
              <h2 style={{ fontSize: "16px" }}>Quality You Can Trust</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              Partnering with us means aligning with an industry name synonymous
              with quality and innovation. Our commitment to excellence ensures
              that you can confidently endorse and promote services that deliver
              tangible value to your audience.
            </div>
          </div>
          <div className={styles.card2}>
            <div>
              <FontAwesomeIcon
                icon={faHandHoldingDollar}
                size="3x"
                style={{ color: "#1266f8" }}
              />
            </div>
            <div>
              <h2 style={{ fontSize: "16px" }}>Easy-to-Manage Portal</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              Experience the convenience of our powerful Affiliate Dashboard,
              designed to streamline your affiliate journey. With intuitive
              tools and concise reports, you can effortlessly track, manage, and
              analyze your referrals, leads, and commissions, ensuring that you
              derive effective results from your efforts.
            </div>
          </div>
          <div className={styles.card4}>
            <div>
              <FontAwesomeIcon
                icon={faArrowTrendUp}
                size="3x"
                style={{ color: "#1266f8" }}
              />
            </div>
            <div>
              <h2 style={{ fontSize: "16px" }}>Industry-Standard Commissions</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              Earn competitive commissions that reward your efforts as our
              affiliate. We believe in recognizing your contributions, and our
              commission structure is designed to reflect the value you bring.
              Join us and get a flat 5% commission on 1st month's revenue with
              the added assurance of timely payments you can rely on.
            </div>
          </div>
          <div className={styles.card3}>
            <div>
              <FontAwesomeIcon
                icon={faTrophy}
                size="3x"
                style={{ color: "#1266f8" }}
              />
            </div>
            <div>
              <h2 style={{ fontSize: "16px" }}>Dedicated Support</h2>
            </div>
            <div style={{ fontSize: "15px" }}>
              Count on our dedicated support team as your partners in success.
              We're here to assist you at every step, ensuring your affiliate
              journey is smooth and profitable. With our support, you'll have
              the guidance and resources you need to boost your revenue and
              reach your goals.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
