import React, { useState } from "react";
import { notifyError } from "../util/Toaster/Toaster";
import Swal from "sweetalert2";

const STATUS = [
  "Pending",
  "Not Qualified",
  "ATC 1 (Attempted to contact)",
  "ATC 2 (Attempted to contact)",
  "ATC 3 (Attempted to contact)",
  "ATC 4 (Attempted to contact)",
  "ATC 5 (Attempted to contact)",
  "ATC 6 (Attempted to contact)",
  "Qualified",
  "Requirement Gathering",
  "Profile(s) shared",
  "Interview Scheduled",
  "Closed by Client",
  "Finalized",
  "Candidate Onboarded",
];

function Select(props) {
  const [value, setValue] = useState(null);
  const showAlertStatus = async (message) => {
    Swal.fire({
      text: message,
      icon: "info",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Okay",
    });
  };

  function isMoreThan24HoursAgo(timestamp) {
    // Convert the given timestamp to milliseconds
    const givenTime = new Date(timestamp).getTime();

    // Get the current time in milliseconds
    const currentTime = new Date().getTime();

    // Calculate the difference in milliseconds
    const timeDifference = currentTime - givenTime;

    // Check if the difference is more than 24 hours (86400000 milliseconds)
    return timeDifference > 86400000;
  }
  const handleStatusChange = (value, id, updatedAt) => {
    if (
      value.startsWith("ATC") &&
      !value.startsWith("ATC 1") &&
      !isMoreThan24HoursAgo(updatedAt)
    ) {
      const message =
        "Please wait for a minimum of 24 hours before making any updates to the status.";
      return showAlertStatus(message);
    }
    setValue(value);
    props.handleStatusChange(value, id, updatedAt);
  };
  return (
    <select
      disabled={
        props.referral.status === "Candidate Onboarded" ||
        props.referral.deleted == "1"
          ? true
          : false
      }
      value={value || props.referral.status}
      onChange={(event) => {
        if (event.target.value === props.referral?.status) {
          props.setCommentTicket((prev) => ({
            ...prev,
            showComment: false,
            showTicket: false,
          }));
          notifyError(
            "Selected props.referral already in " +
              props.referral?.status +
              " status"
          );
        } else {
          handleStatusChange(
            event.target.value,
            props.referral.id,
            props.referral.updatedAt
          );
        }
      }}
    >
      {props.referral?.deleted == "1" ? (
        <option key={1} value="Deleted">
          Deleted by user
        </option>
      ) : (
        STATUS.map((itm, i) => (
          <option key={i} value={itm}>
            {itm}
          </option>
        ))
      )}
    </select>
  );
}

export default Select;
