import React from "react";
import { Link } from "react-router-dom";
import "./Button.css";

const Button = () => {
  return (
    <Link
      to="/register"
      className="button3 td"
      style={{ textDecoration: "none !important" }}
    >
      <button className="affilate-button">Start Today and Earn Big</button>
    </Link>
  );
};

export default Button;
