import { notifyError } from "../components/util/Toaster/Toaster";
import { api, setAuthToken } from "../Api";
import { isLoader, setUser } from "../Redux/actions/userActions";

const fetchUserDetails = async (dispatch) => {
    const storedToken = localStorage.getItem("loggedUserToken");
    if (storedToken) {
      dispatch(isLoader(true))
      setAuthToken(storedToken);
      try {
        const response = await api.get("/user-details");
        if (response.status === 200) {
          const user = response.data.user;
          const userObject = {
            userName: user?.userName,
            userId: user?.id,
            fullName: user?.fullName,
            email: user?.email,
            password: user?.password,
            mobile: user?.mobile,
            source: user?.source,
            country: user?.country,
            linkedin: user?.linkedin,
            createdAt: user?.createdAt,
            updatedAt: user?.updatedAt,
            userType: user?.userType,
            referralId: user?.referral_id,
          };
          dispatch(setUser(userObject));
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          notifyError(error.response.data.message)
          dispatch(setUser(null))
          localStorage.removeItem('loggedUserToken');

        } else if (error.response) {
          notifyError(error.response.data.message)
        } else {
          notifyError("Something went wrong");
        }
      } finally {
        dispatch(isLoader(false))
        // setLoading(false); // Set loading to false when fetching is complete
      }
    } else {
      notifyError("Session Expired please login again.")
      dispatch(setUser(null))
    }
};

export {
    fetchUserDetails
}