import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

const GoBack = ({url}) => {
  const navigate = useNavigate();
  function goBack() {
    navigate(url ?? -1); // Navigates back to the previous page
  }
  return (
    <div
      style={{
        // background: "#126AFF",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "1rem",
        padding: "1rem 1rem",
        cursor: "pointer",
      }}
    >
      <FontAwesomeIcon
        icon={faArrowLeft}
        style={{ color: "#126AFF", fontSize: "1.7rem" }}
        onClick={goBack}
      />
      <p
        style={{ color: "#126AFF", fontSize: "1.4rem", fontWeight: "500" }}
        onClick={goBack}
      >
        Go Back
      </p>
    </div>
  );
};

export default GoBack;
