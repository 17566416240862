import styles from './Unsubscribe.module.css';
import { useState } from 'react';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

//api 
import { unsubscribeEmail } from '../../../api/unsubscribe';

const ColorButton = withStyles((theme) => ({
    root: {
        backgroundColor: '#126AFF',
        borderRadius: '100px',
        textTransform: "capitalize"
    },
}))(Button);

const transformNumberToBoolean = (val) => {
    if (val === 1) {
        return true
    }
    return false
}

const Unsubscribe = (props) => {
    const { userData } = props;
    const [check, setCheck] = useState({
        promotional: transformNumberToBoolean(userData?.promotionalEmail) ?? true,
        newsletter: transformNumberToBoolean(userData?.newsletterEmail) ?? true,
        all: transformNumberToBoolean(userData?.allEmail) ?? true
    });

    const handleChange = (event) => {
        setCheck({ ...check, [event.target.name]: event.target.checked });
    };

    const handleSaveChanges = async () => {
        try {
            const res = await unsubscribeEmail({ email: userData?.email, ...check })
        } catch (error) {
            console.log(error)
        }
    }

    return <div className={styles.container}>
        <h1>Unsubscribe Email</h1>
        <p className={styles.txtMsg}>Please mark all the services for which you don't wish to recieve emails.</p>
        <div className={styles.card}>
            <p className={styles.txtMsg}>{userData.email} email preferences.</p>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox color='primary' checked={check.promotional} onChange={handleChange} name="promotional" />}
                    label="Promotional Emails."
                />
                <FormControlLabel
                    control={<Checkbox color='primary' checked={check.newsletter} onChange={handleChange} name="newsletter" />}
                    label="Newsletter Emails."
                />
                <FormControlLabel
                    control={<Checkbox color='primary' checked={check.all} onChange={handleChange} name="all" />}
                    label="All Emails."
                />
            </FormGroup>
            <br></br>
            <ColorButton variant="contained" color="primary" onClick={handleSaveChanges}>
                Save changes
            </ColorButton>
        </div>
    </div>
}

export default Unsubscribe;