export const setUser = (user) => ({
  type: 'SET_USER',
  payload: user,
});

export const isLoader = (loading) => ({
  type: 'IS_LOADING',
  payload: loading
})

export const modalState = (state) => ({
  type: 'MODAL_STATE',
  payload: state
})