import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./UserProfile_d.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { api, setAuthToken } from "../../../Api";
import { isLoader, setUser } from "../../../Redux/actions/userActions";
import { notifyError, notifySuccess } from "../../util/Toaster/Toaster";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { countryList } from "../../../helpers/countryList";

// import TextField from "@material-ui/core/TextField";
import { TextField } from "@material-ui/core";
import { fetchUserDetails } from "../../../api/user";
// Tabs component import
import TabPanel from "../../../pageComponets/profile/TabPanel";
import {
  StyledTab,
  StyledTabs,
} from "../../../pageComponets/profile/TabStyles";
import { faPenAlt, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { validate } from "../../util/Validation";

const UserProfile = (props) => {
  const loggedUser = useSelector((state) => state.user.loggedUser);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEdit = searchParams.get("isEdit");

  const [value, setValue] = useState(0); //tabs value
  const [isEditing, setIsEditing] = useState(true);

  const sourcesArr = [
    "Search Engine (Google, Bing, Yahoo)",
    "Google Ads",
    "Facebook",
    "Youtube",
    "Twitter",
    "Instagram",
    "LinkedIn",
    "Blog/Article",
    "Email",
    "Word of Mouth",
    "Others",
  ];

  const handleChange = (event, newValue) => {
    // handles tab change
    setValue(newValue);
  };

  useEffect(() => {
    if (isEdit == 1) {
      setValue(1);
      setIsEditing(false);
    } else if (isEdit == 2) {
      setValue(1);
      setIsEditing(true);
    } else {
      setValue(0);
      setIsEditing(true);
    }
  }, [isEdit]);

  const [details, setDetails] = useState({
    country: loggedUser.country,
    linkedin: loggedUser.linkedin,
    source: loggedUser.source,
    formatted_phone_number: loggedUser?.mobile,
    country_code: "IN",
    dial_code: "+91",
  });

  const [others, setOthers] = useState(false);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [country, setCountry] = useState("");
  const [bankCountryName, setBankCountryName] = useState("");
  const [bankDetails, setBankDetails] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const { fullName, email, userId, country } = loggedUser;
    setFullName(fullName);
    setEmail(email);
    setUserId(userId);
    setCountry(country);
    const fetchBankDetails = async () => {
      try {
        // setAuthToken(localStorage.getItem('loggedUserToken'));
        const response = await api.get(`/bank/${userId}`);
        const {
          bank_name,
          bank_code,
          branch_name,
          account_number,
          country,
          swift_code,
        } = response.data.bankDetails;
        setAccountNumber(account_number);
        setIfscCode(bank_code);
        setSwiftCode(swift_code ?? "");
        setBankName(bank_name);
        setBankBranch(branch_name);
        setBankDetails(response.data.bankDetails);
        // notifySuccess(response.data.message)
        setBankCountryName(country);
        // Set other bank details as needed
      } catch (error) {
        if (error.response && error.response.status === 401) {
          notifyError(error.response.data.message);
          dispatch(setUser(null));
          navigate("/login");
        } else if (error.response) {
          // notifyError(error.response.data.message);
        } else {
          // notifyError("Something went wrong");
        }
      }
    };
    fetchBankDetails();
  }, [loggedUser]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleNameUpdateClick = async (e) => {
    e.preventDefault();
    dispatch(isLoader(true));
    try {
      const response = await api.put(`/user/update-profile/${userId}`, {
        fullName,
        ...details,
      });
      notifySuccess(response.data.message);
      // window.location?.reload();
      fetchUserDetails(dispatch);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        dispatch(setUser(null));
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
      dispatch(isLoader(false));
    } finally {
      // dispatch(isLoader(false));
      setIsEditing(false);
    }
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    if (!ifscCode && !swiftCode) {
      notifyError("Please enter IFSC code or Swift code");
      return;
    }
    const req = {
      "account number": accountNumber,
      // "ifsc code": ifscCode,
      "bank name": bankName,
      "bank address ": bankBranch,
      // "swift code": swiftCode,
      "user id": userId,
      country: bankCountryName,
    };

    if (!validate(req)) {
      return;
    }

    dispatch(isLoader(true));

    const newreq = {
      account_number: accountNumber,
      bank_code: ifscCode,
      bank_name: bankName,
      branch_name: bankBranch,
      swift_code: swiftCode,
      user_id: userId,
      country: bankCountryName,
    };

    try {
      let response = {};
      if (bankDetails) {
        response = await api.put(`/bank/${userId}`, newreq);
      } else {
        response = await api.post("/bank", newreq);
      }
      notifySuccess(response.data.message);
      dispatch(isLoader(false));

      navigate("/profile/?isEdit=2");

      // setIsEditing(true);
      // fetchUserDetails(dispatch);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notifyError(error.response.data.message);
        dispatch(setUser(null));
        navigate("/login");
      } else if (error.response) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Something went wrong");
      }
      dispatch(isLoader(false));
    }
  };

  const handleCancelClick = () => {
    setFullName(loggedUser.fullName);
    setIsEditing(false);
    if (!bankDetails) {
      return true;
    }
    const {
      bank_name,
      bank_code,
      branch_name,
      account_number,
      country,
      swift_code,
    } = bankDetails;
    // Reset the input fields to their original values
    setAccountNumber(account_number);
    setIfscCode(bank_code);
    setSwiftCode(swift_code);
    setBankName(bank_name);
    setBankBranch(branch_name);
    setBankCountryName(country);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const handleOnChangePhone = (value, data, event, formattedValue) => {
    if (value !== undefined) {
      setDetails({
        ...details,
        phone_number: value,
        formatted_phone_number: formattedValue,
        country_code: data.countryCode,
        dial_code: "+" + data.dialCode,
      });
    }
  };

  return (
    <div className="user-profile">
      <div className="card-head">
        <div style={{ display: "flex", gap: "10px", margin: 0 }}>
          <h3 style={{ margin: 0 }} className="page-title">
            Edit Profile
          </h3>
          <FontAwesomeIcon
            onClick={() => setIsEditing(!isEditing)}
            style={{ fontSize: "21px", margin: 0, cursor: "pointer" }}
            icon={faPenToSquare}
          />
        </div>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="division-tabs"
        >
          <StyledTab label="User Information" {...a11yProps(0)} />
          {loggedUser.userType !== "Admin" && (
            <StyledTab label="Bank Details" {...a11yProps(1)} />
          )}
        </StyledTabs>
      </div>

      <TabPanel value={value} index={0}>
        {/* User information Edit */}
        <div className="panel-content">
          <div className="text-section">
            <label className="text-label">Full Name</label>
            <TextField
              type="text"
              maxLength={100}
              id="fullname-edit"
              variant="outlined"
              style={{
                fontWeight: "bolder",
                background: isEditing ? "" : "white",
              }}
              value={fullName}
              disabled={isEditing}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className="text-section">
            <label className="text-label">Email</label>
            <TextField
              type="text"
              maxLength={100}
              id="email-edit"
              variant="outlined"
              value={email}
              disabled
              // onChange={(e) => set(e.target.value)}
              style={{ background: isEditing ? "" : "white" }}
            />
          </div>
          <div className="text-section">
            <label className="text-label">Phone Number</label>
            <PhoneInput
              country={details.country_code}
              // countryCodeEditable={false}
              placeholder="Enter phone number"
              value={details.formatted_phone_number}
              onChange={handleOnChangePhone}
              disabled={isEditing}
              containerClass={"custom-phone-input-edit"}
              inputClass={"custom-phone-input-edit"}
              buttonClass={"custom-phone-btn-edit"}
              required={true}
            />
          </div>

          {/* <div className="text-section">
            <label className="text-label">Where did you find us?</label>
            <select
              id="how_many_resources_do_you_need_-input"
              className="hs-form__field__input"
              name="team_size"
              required={true}
              style={{
                padding: "10px 1rem",
                // fontWeight: "bolder",
                background: isEditing ? "" : "white",
              }}
              disabled={isEditing}
              aria-invalid="false"
              aria-describedby="how_many_resources_do_you_need_-description"
              aria-required="true"
              value={details.source}
              // onChange={handleInputChange}
              onChange={(e) => {
                let selected = e.target.value;
                if (selected === "Others") {
                  setOthers(true);
                  setDetails({ ...details, source: "" });
                } else {
                  setOthers(false);
                  setDetails({ ...details, source: selected });
                }
              }}
            >
              <option
                label={
                  details?.source && loggedUser?.source == details?.source
                    ? details?.source
                    : "Select Source"
                }
                disabled=""
                value=""
              >
                Select Source
              </option>
              {sourcesArr.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
            {others && (
              <TextField
                type="text"
maxLength={100}
                id="fullname-edit"
                variant="outlined"
                style={{
                  fontWeight: "bolder",
                  background: isEditing ? "" : "white",
                }}
                placeholder="Other Source"
                value={details.source}
                onChange={(e) =>
                  setDetails({ ...details, source: e.target.value })
                }
                disabled={isEditing}
              />
            )}
          </div> */}

          <div className="text-section">
            <label className="text-label">Select Country</label>
            <select
              id="how_many_resources_do_you_need_-input"
              className="hs-form__field__input"
              name="team_size"
              required={true}
              style={{
                padding: "10px 1rem",
                // fontWeight: "bolder",
                background: isEditing ? "" : "white",
              }}
              disabled={isEditing}
              aria-invalid="false"
              aria-describedby="how_many_resources_do_you_need_-description"
              aria-required="true"
              value={details.country}
              // onChange={handleInputChange}
              onChange={(e) => {
                let selected = e.target.value;
                setDetails({ ...details, country: selected });
              }}
            >
              <option label="Select Country" disabled="" value="">
                Select Country
              </option>
              {countryList.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          <div className="text-section">
            <label className="text-label">Linkedin URL</label>
            <TextField
              type="text"
              maxLength={100}
              id="fullname-edit"
              variant="outlined"
              style={{
                fontWeight: "bolder",
                background: isEditing ? "" : "white",
              }}
              placeholder="Linkedin URL"
              value={details.linkedin}
              disabled={isEditing}
              onChange={(e) =>
                setDetails({ ...details, linkedin: e.target.value })
              }
            />
          </div>

          <button className="btn btn-primary" onClick={handleNameUpdateClick}>
            Save
          </button>
        </div>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <div className="panel-content">
          <div className="text-section">
            <label className="text-label">Account Number</label>
            <TextField
              id="accountnumber-edit"
              variant="outlined"
              type="number"
              placeholder="Account Number"
              onKeyDown={(e) => {
                if (e.key === "+") {
                  e.preventDefault();
                }
                if (e.key === "-") {
                  e.preventDefault();
                }
              }}
              value={accountNumber}
              disabled={isEditing}
              autoFocus="true"
              onChange={(e) => setAccountNumber(e.target.value)}
              style={{ background: isEditing ? "" : "white" }}
            />
          </div>

          <div className="text-section">
            {/* IFSC code*/}
            <label className="text-label">IFSC Code</label>
            <TextField
              type="text"
              maxLength={100}
              id="ifsccode-edit"
              variant="outlined"
              placeholder="IFSC Code"
              disabled={isEditing}
              value={ifscCode}
              onChange={(e) => setIfscCode(e.target.value)}
              style={{ background: isEditing ? "" : "white" }}
            />
          </div>

          <div className="text-section">
            {/* <span style={{color:'grey', fontStyle:"italic"}}>(Optional)</span> */}
            {/* Swift */}
            <label className="text-label">Swift Code</label>
            <TextField
              type="text"
              maxLength={100}
              id="ifsccode-edit"
              variant="outlined"
              placeholder="Swift Code"
              disabled={isEditing}
              value={swiftCode}
              onChange={(e) => setSwiftCode(e.target.value)}
              style={{ background: isEditing ? "" : "white" }}
            />
          </div>

          <div className="text-section">
            {/* Bank Name */}
            <label className="text-label">Bank Name</label>
            <TextField
              type="text"
              maxLength={100}
              id="bankname-edit"
              variant="outlined"
              placeholder="Bank Name"
              value={bankName}
              disabled={isEditing}
              onChange={(e) => setBankName(e.target.value)}
              style={{ background: isEditing ? "" : "white" }}
            />
          </div>

          <div className="text-section">
            {/* Bank Address */}
            <label className="text-label">Bank Address</label>
            <TextField
              type="text"
              maxLength={100}
              id="bankbranch-edit"
              variant="outlined"
              placeholder="Bank Address"
              value={bankBranch}
              disabled={isEditing}
              onChange={(e) => setBankBranch(e.target.value)}
              style={{ background: isEditing ? "" : "white" }}
            />
          </div>

          <div className="text-section">
            <label className="text-label">Select Country</label>
            <select
              id="how_many_resources_do_you_need_-input"
              className="hs-form__field__input"
              name="team_size"
              required={true}
              disabled={isEditing}
              value={bankCountryName}
              onChange={(e) => setBankCountryName(e.target.value)}
              style={{ background: isEditing ? "" : "white" }}
            >
              <option label="Select Country" disabled="" value="">
                Select Country
              </option>
              {countryList.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          <div className="text-section">
            {/* Save and Cancel Buttons */}
            <button className="btn btn-primary" onClick={handleSaveClick}>
              Save
            </button>
            {/* <button className="btn btn-secondary" onClick={handleCancelClick}>Cancel</button> */}
          </div>
        </div>
      </TabPanel>
      <br />
      <br />
    </div>
  );
};

export default UserProfile;
